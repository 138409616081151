.ajax-loading-modal {
  .box {
    &.confirmation {
      padding: 0 !important;
    }
    .smc-post-confirmation-wrapper {
      .table {
        &.top {
          .cell {
            padding: 40px 10px !important;
            .box {
              padding-bottom: 20px;
              .icon {
                padding: 0 0 20px 0;
                i {
                  color: $color-green;
                  border: 2px solid $color-green;
                  width: 32px;
                  height: 32px;
                  font-size: 12px;
                  text-align: center;
                  line-height: 30px;
                  border-radius: 50%;
                  overflow: hidden;
                  display: inline-block;
                }
                &.plain {
                  i {
                    border: 0;
                    font-size: 32px;
                    border-radius: 0;
                    line-height: 32px;
                    padding: 0;
                    width: auto;
                  }
                }
              }
              h3 {
                padding: 0 0 10px 0 !important;
                font-weight: 500;
                color: #3b4858;
              }
              p {
                font-size: 12px !important;
                color: #8392A6;
                &.post_title {
                  padding: 40px 0 0 0 !important;
                  // color: #6C6C6C;
                  i.icon {
                    display: inline-block;
                    color: #fff;
                    background-color: #efefef;
                    height: 14px;
                    width: 14px;
                    line-height: 14px;
                    font-size: 14px;
                    vertical-align: top;
                    text-align: center;
                    margin: 0 5px 0 0;
                    border-radius: 1px;
                    padding: 0;
                    &.icon-twitter {
                      background-color: #5bbfde;
                    }
                    &.icon-facebook {
                        background-color: #4267B2;
                    }
                    &.icon-instagram {
                        background-color: #ffc82b;
                    }
                    &.icon-linkedin {
                        background-color: #007bb6;
                    }
                  }
                  span {
                    color: $color-gray;
                    a {
                      text-decoration: underline;
                      color: $color-blue;
                    }
                  }
                }
              }
              &.draft {
                .icon {
                  i {
                  }
                }
                p {
                  &.post_title {
                    i {
                    }
                  }
                }
              }
              &.published {
                .icon {
                  i {
                  }
                }
                p {
                  &.post_title {
                    i {
                    }
                  }
                }
              }
              &.unpublish {
                .icon {
                  i {
                    color: $color-red;
                    border-color: $color-red;
                  }
                }
                p {
                  &.post_title {
                    i {
                    }
                  }
                }
              }
              &.delete {
                .icon {
                  i {
                    color: $color-red;
                    border-color: $color-red;
                  }
                }
                p {
                  &.post_title {
                    i {
                    }
                  }
                }
              }
              &.gray {
                .icon {
                  i {
                    color: $color-gray;
                    border-color: $color-gray;
                  }
                }
                p {
                  &.post_title {
                    i {
                    }
                  }
                }
              }
              &.scheduled {
                .icon {
                  i {

                  }
                }
                p {
                  &.post_title {
                    i {
                    }
                  }
                }
              }
              &.autoscheduled {
                p {
                  &.post_title {
                    i {
                    }
                  }
                }
              }
            }
          }
        }
        &.bottom {
          background-color: #EBEEF5;
          .cell {
            padding: 10px 0 !important;
            @include mobile {
              /*display: table-cell !important;*/
            }
          }
        }
        .row {
          &.confirm-button {
            .button {
              display: inline-block !important;
              padding: 0;
              height: 36px;
              line-height: 36px;
              width: 120px;
              margin: 0 auto;
              text-align: center;
            }
          }
          .cell {
            &.col {
              &.col-75 {
              }
              &.col-50 {
              }
              &.col-25 {
              }
            }
            .box {
              border: 0;
            }
          }
        }
      }
    }
  }
}
