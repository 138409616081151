@import "/node_modules/video.js/dist/video-js.min.css";

.video-js {
  .vjs-error .vjs-error-display:before {
    display: none;
  }
  .vjs-remaining-time-display {
    display: inline-block;
    vertical-align: middle;
  }
  .vjs-big-play-button {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin: -32px 0 0 -32px;
    vertical-align: middle;
    border-width: 1px;
    background: none;
    line-height: 64px;
    font-size: 32px;
    .vjs-icon-placeholder {
      display: inline-block;
      height: 62px;
      position: relative;
      width: 62px;
      line-height: 62px;
      font-size: 32px;
      .vjs-control-text {

      }
    }
  }
}

#social-media-calendar-preview {

.table.layout-auto {
    table-layout: auto;
    background-color: white;
}

.table .row>.cell:last-child {
  padding-right:40px!important;
}

  .status_header {
    font-size: 14px !important;
    font-weight: 100;
    white-space: normal;
    > span.date {
      font-weight: 100 !important;
      opacity: 0.8;
      vertical-align: middle;
      a {
        text-decoration: underline;
        color: $color-blue;
      }
    }
    i.icon {
      height: 14px;
      width: 14px;
      line-height: 14px;
      font-size: 6px;
      box-sizing: content-box;
      padding: 0;
      margin: 0 6px 0 0;
      &.circle {
        margin: 0 12px 0 0;
      }
    }
  }

  &.draft {
    .status_header {
      i.icon {
        &.circle {
          color: $color-gray;
          border-color: $color-gray;
        }
      }
    }
  }
  &.published {
    .status_header {
      i.icon {
        &.circle {
          color: $color-green;
          border-color: $color-green;
        }
      }
    }
  }
  &.autoscheduled {
    .status_header {
      i.icon {
        color: $color-blue;
        border: 0;
        border-radius: 0;
        height: 16px;
        width: 16px;
        line-height: 16px;
        font-size: 16px;
      }
    }
  }
  &.scheduled {
    .status_header {
      i.icon {
        color: $color-orange;
        border: 0;
        border-radius: 0;
        height: 16px;
        width: 16px;
        line-height: 16px;
        font-size: 16px;
      }
    }
  }

  .hero {
    .page-container {
      margin: 0 !important;
    }
  }
  &.page-container {
    &.fluid {
      display: table-cell;
      width: auto;
      vertical-align: top;
      //padding: 0 40px;
    }
  }
  .content-header-text {
    .heading {
      vertical-align: middle;
      span.text {
        display: inline-block;
        vertical-align: middle;
      }
      a.caret {
        span.icon-caret-left {
          font-size: 18px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 10px 0 0;
          line-height: 1;
        }
        &:hover {
          color: $color-blue;
        }
      }
    }
  }
  .button-wrapper {
    text-align: right;
    display: block;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;
    @media (max-width: $breakpoint-large) {
      white-space: normal;
    }
    button, a {
      margin: 0 0 0 10px;
      &:first-child {
        margin: 0 0 0 0;
      }
    }
  }

  .content-main {
    margin: 0;
    padding: 40px 0 40px 40px;
    border: 0 !important;
    &.preview-post {
      /* START table layout */
      form {
        > .table {
          >.row {
            >.cell {
              vertical-align: top;
              &.left {
                width: 70%;
                padding-right: 1%;
                >.box {
                  background-color: transparent;
                  border-radius: 0;
                  border: 0;
                  .smc_item {
                    > table.box {
                      > tbody {
                        > tr{
                          &.created {
                            > td {
                              p {
                                font-size: 14px;
                              }
                            }
                          }
                          &.image {
                            > td {
                              vertical-align: middle;
                              text-align: center;
                              img {
                                max-width: 100%;
                                max-height: 100%;
                                width: auto;
                                vertical-align: middle;
                                display: inline-block;
                                position: relative;
                                float: none;
                              }
                              .video-js {
                                display: inline-block;
                                max-width: 100%;
                                width: 100%;
                                height: 360px;
                              }
                            }
                            &.no-image {
                              > td {
                                height: 220px;
                              }
                            }
                          }
                          &.categories {
                            > td {
                              p {
                                font-size: 14px;
                                padding: 24px 34px 12px 34px;
                                .overflow {
                                  padding: 0 34px 12px 34px;
                                }
                              }
                            }
                          }
                          &.title {
                            > td {
                              p {
                                padding: 12px 34px 12px 34px;
                              }
                            }
                          }
                          &.post_content {
                            > td {
                              p {
                                padding: 20px 34px 48px 34px;
                              }
                            }
                          }
                          &.status {
                            > td {
                              p {
                                span.date {
                                }
                              }
                            }
                          }
                          &.statistics {
                            > td {
                              > table {
                                > tbody {
                                  > tr {
                                    > td {
                                      p {
                                        i {
                                          &:before {
                                          }
                                          margin-top:-2px;
                                        }
                                        span {
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &.status_footer {
                            > td {
                              p {
                              }
                            }
                          }
                        }
                      }
                    }
                    &.draft {
                      > table.box {
                        > tbody {
                          > tr.statistics {
                            > td {
                              > table {
                                > tbody {
                                  > tr {
                                    > td {
                                      p {
                                      }
                                      i {
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          > tr.status_footer {
                            > td {
                              p {
                              }
                            }
                          }
                        }
                      }
                    }
                    &.published {
                      > table.box {
                        > tbody {
                          > tr.statistics {
                            > td {
                              > table {
                                > tbody {
                                  > tr {
                                    > td {
                                      p {
                                      }
                                      i {
                                        &.icon-comments {
                                        }
                                        &.icon-heart {
                                        }
                                        &.icon-shares {
                                        }
                                        &.icon-clicks {
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          > tr.status_footer {
                            > td {
                              p {
                              }
                            }
                          }
                        }
                      }
                    }
                    &.scheduled {
                      > table.box {
                        > tbody {
                          > tr.statistics {
                            > td {
                              > table {
                                > tbody {
                                  > tr {
                                    > td {
                                      p {
                                      }
                                      i {
                                        &.icon-comments {
                                        }
                                        &.icon-heart {
                                        }
                                        &.icon-shares {
                                        }
                                        &.icon-clicks {
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          > tr.status_footer {
                            > td {
                              p {
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.right {
                width: 30%;
                padding-left: 1%;

                .box {
                  &.date {
                    .table {
                      .row {
                        .cell {
                          white-space: nowrap;
                          &.icon {
                            width: 40px !important;
                          }
                          i.icon {
                            color: $color-green;
                            font-size: 16px;
                            width: 32px;
                          }
                          select {
                            display: inline-block;
                            width: auto;
                            float: left;
                            margin: 0;
                            position: relative;
                            &#post-publish-hour {
                              width: 30%;
                              margin: 0 5% 0 0;
                            }
                            &#post-publish-minute {
                              width: 30%;
                              margin: 0 5% 0 0;
                            }
                            &#post-publish-noon {
                              width: 30%;
                            }
                          }
                        }
                      }
                    }
                  }
                  .select-modal-button {
                    display: block;
                  }
                }

                .form-group {
                  &.form-group-token-select {
                    .input {
                      .tokenfield {
                        .token {
                        }
                        input {
                          &.token-input {
                          }
                        }
                      }
                    }
                  }
                }
                p {
                  font-size: 12px;
                  padding: 10px 0 0 0;
                  span {
                    font-weight: 600;
                    word-break: break-word;
                  }
                }
              }
            }
          }
        }
      }
      /* END table layout */

      form#post-preview-form {
        .cell {
          &.right {
            .box {
              &:first-child {
              }
            }
          }
        }
      }

      input[type=submit] {
        display: none;
      }
    }
  }
}

.cell {
  .box {
    background-color: #fff;
    padding: 0;
    margin: 0 0 0 0;
    position: relative;
    display: block;
    border-radius: 3px;
    border: 1px solid $color-light-gray;
    &:first-child {
      margin: 0;
    }
    margin: 10px 0 0 0;
    &.transparent {
      border: 0;
      background-color: transparent;
    }
    &.add-buttons {
      .button {
        text-transform: uppercase;
        font-weight: 400;
      }
    }


    .form-group {
      padding: 20px;
      margin: 0;

      h3{
      color:  #3B4858;
      font-size: 14px;
      }
      #post-publish-date{

        margin-bottom:10px
      }

     select[type=select]{


     color:#8392A6;
     font-weight: 400;
     }

     .button--white:hover{

      box-shadow:none;
       border-color: #dee2ec;
     }
      &.form-group-input {
        > .input {
          border: 1px solid #dbe0ea;
          border-radius: 3px;
          padding: 10px;
        }
        .select-items-modal{
          top: initial;
          width: 96%;
          margin: 4px 2% 0 2%;
        }
      }
      &.form-group-content-editor {
        padding-top: 0;
        > .input {
          margin: 0;
          border: 1px solid #dbe0ea;
          border-radius: 3px;
          padding: 10px;
          > textarea {
            border: 0;
            padding: 0 !important;
          }
        }
      }
    }
  }
  &.left {
    .box {
      .form-group {
        &.form-group-input {
          .input {
            margin: 0;
            input {
              border: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
