select[type=select] {
	font-size: 13px;
	font-weight: 400;
	display: block;
	width: 100%;
	color: $color-text;
	border: 1px solid $color-light-gray;
	border-radius: 3px;
	padding: 0;
	background-color: #fff;
	height: 34px;

	@include placeholder {
		color: $color-input-placeholder;
	}

	&[disabled] {
		color: #7f7f7f;
        background-color: #efefef;
        pointer-events: none;
	}

}
