.select-modal-button {
  padding: 13px 40px 12px 20px;
  margin: 0 0 4px 0;
  border-radius: $border-radius;
  box-shadow: none;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  white-space: nowrap;
  cursor: pointer;
  width: auto;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  z-index: 2;
  font-weight: 400;
  color: #56606F;
  /*min-width: 182px;*/

  > .label {
    white-space: normal;
    word-break: break-word;
  }

  @media (max-width: $breakpoint-large) {
    display: block;
    float: none !important;
    margin-bottom: 20px !important;
  }

  &:hover {
    color: $color-black;
  }

  &:first-child {
    margin: 0 0 4px 0;
  }

  &[disabled] {
    color: #7f7f7f;
    background-color: #efefef;
    pointer-events: none;
  }
  i {
    color: $color-light-green;
    font-size: 6px;
    padding: 0 15px;
    float: none;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    vertical-align: middle;
    background-color: #fff;
    &:before {
      margin: -3px 0 0 0;
      display: table-cell;
      vertical-align: middle;
      padding: 0 10px;
      box-sizing: border-box;
      position: absolute;
      float: none;
      top: 50%;
      left: 0;
    }
  }

  &.open {
    cursor: default;
    overflow: visible;
    z-index: 3;

    @media (max-width: $breakpoint-large) {
      position: fixed;
      top: 0;
      left: 0;
      margin: 0 !important;
      width: 94% !important;
      height: 100%;
      padding: 20px 3% 0 3% !important;
      z-index: 1000;
      background-color: rgba(0,0,0,.8);
      border: 0;
      color: #fff;
      box-sizing: content-box !important;

      &:hover {
        border-color: transparent;
      }

      i {
        display: none !important;
      }
    }

    i {
      &:before {
        display: inline-block;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}

.select-items-modal {
  background-color: #fff;
  border: 1px solid #dee2ec;
  display: none;
  position: absolute;
  height: auto;
  box-sizing: border-box;
  padding: 0;
  left: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 5px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  top: 100%;
  margin: 2px 0 0 0;
  width: auto;
  min-width: 180px;

  .mask {
    display: none;
    position: fixed;
    background: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.open {
    display: block;
    z-index: 2;
    overflow: visible;
    .mask {
      display: block;
    }
  }

  @media (max-width: $breakpoint-large) {
    position: fixed;
    top: 0 !important;
    left: 0;
    width: 94%!important;
    z-index: 1000!important;
    margin: 40px 3% 0 3% !important;
    border-radius: 5px;
  }

  .apply {
    display: block;
    padding: 20px;
    height: 56px;
    text-align: left;
    a {
      color: #fff;
      font-size: 12px;
      padding: 5px;
      border-radius: 3px;
      margin: 0 0 0 10px;
      &.select-items-modal-apply {
        background-color: $color-green;
      }
      &.select-items-modal-clear {
        background-color: $color-light-gray;
      }
      &:first-child {
        margin-left: 0;
      }
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .select-items-modal-container {
    background-color: #fff;
    position: relative;
    z-index: 1000;
    > .select-items-list {
      > .select-items-list-container {
        border: 0;
        border-radius: 0;
        margin: 0;
        .select-items-list-inner {
          display: table;
          width: 100%;
          border-spacing: 0;
          border-collapse: separate;
          padding: 20px;
          margin: 0;
          position: relative;
          float: none;
          background-color: #F9F9F9;
          .cell {
            padding: 0 0 0 20px;
            border-left: 1px dotted $color-light-gray;
            padding: 0 0 0 20px;
            border-left: 1px dotted #dee2ec;
            display: table-cell;
            text-align: left;
            vertical-align: top;
            position: relative;
            &:first-child {
              padding: 0;
              border-left: 0;
            }
            > div {
              &.form-group-select-items-modal-items {
                display: block;
                padding: 0 5px 0 0;
                > .label {
                  padding: 0 0 5px 0;
                  margin: 10px 0 0 0;
                  font-size: 11px;
                  font-weight: 600;
                  color: #3B4858;
                }
              }
              .form-group-input-type-input {
                margin: 0;
                padding: 4px 0;
                label {
                  font-weight: 400;
                  font-size: 11px;
                  text-transform: capitalize;
                  display: inline-block;
                  position: relative;
                  color: #8392A6;
                }
              }
            }
          }
        }
      }
    }
  }

  &.inline {
    display: block;
    box-shadow: none;
    border: 0;
    margin: 0;
    background: none;
    background-color: transparent;
    left: inherit;
    top: inherit;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    min-width: auto;

    .select-items-modal-container {
      background-color: transparent;
      position: relative;
      z-index: 1;
      > .select-items-list {
        > .select-items-list-container {
          .select-items-list-inner {
            display: block;
            width: auto;
            border-spacing: 0;
            padding: 0;
            margin: 0;
            position: relative;
            float: none;
            background-color: transparent;
            .cell {
              display: block;
              position: relative;
              float: none;
              > div {
                &.form-group-select-items-modal-items {
                  display: block;
                  padding: 0;
                  > .label {
                  }
                }
                .form-group-input-type-input {
                  margin: 0;
                  padding: 0;
                  display: inline-block;
                  position: relative;
                  vertical-align: middle;
                  float: none;
                  label {
                    padding: 0;
                    &:before {
                      position: relative;
                      display: inline-block;
                      float: none;
                      vertical-align: middle;
                      transform: none;
                      margin: 0 10px 0 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
