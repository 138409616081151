section.navigation-permissions {
  h2 {
    margin-bottom: 10px;
  }

  * {
    input[type="checkbox"] {
      display: unset;
      visibility: visible;
      -webkit-appearance: checkbox;
    }
  }

  ul {
    li {
      margin-bottom: 10px;
    }
  }
}
