body {
  #social-media-calendar-create {
    > .header{
      background-color: #fff;
      .hero {
      }
      .page-container {
        margin: 0;
      }
      .buttons {
        .page-container {
          text-align: right;
          white-space: nowrap;
        }
      }
    }
    .content-header {
      display: table;
      .content-header-text {
        .heading {
          vertical-align: middle;
          span.text {
            display: inline-block;
            vertical-align: top;
            word-break: normal;
          }
          a.caret {
            span.icon-caret-left {
              font-size: 18px;
              display: inline-block;
              vertical-align: middle;
              margin: 0 10px 0 0;
              line-height: 1;
            }
            &:hover {
              color: $color-blue;
            }
          }
        }
      }
      .button-wrapper {
        text-align: right;
        width: auto;
        table-layout: auto;
        float: none;
        vertical-align: middle;
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: nowrap;
        @media (max-width: $breakpoint-large) {
          white-space: normal;
        }

        button {
          margin: 0 0 0 5px;
          float: none;
          display: inline-block;
          position: relative;

          &:first-child {
            margin: 0 0 0 0;
          }
        }
      }
    }

    .content-main {
      border: 0;
      margin-top: 0;
      &.create-edit-post {
        /* START table layout */
        form {
          >.table:not(#schedule_date) {
            .row {
              display: table-row;
              .cell {
                display: table-cell;
                vertical-align: top;
                position: relative;
                float: none;
                &.left {
                }
                &.right {
                  .box {
                    border: 0;
                    .form-group {
                      padding: 30px !important;
                      h3 {
                        padding: 0;
                      }
                      .icon {
                        display: inline-block;
                        color: #fff;
                        background-color: #efefef;
                        height: 20px;
                        width: 20px;
                        line-height: 20px;
                        font-size: 20px;
                        vertical-align: top;
                        text-align: center;
                        margin: 0;
                        border-radius: 1px;
                        &.icon-facebook {
                          background-color: #4267B2;
                        }
                        &.icon-twitter {
                          background-color: #5bbfde;
                        }
                        &.icon-linkedin {
                          background-color: #007bb6;
                        }
                      }
                      .form-group {
                        &.form-group-token-select {
                          .input {
                            .tokenfield {
                              .token {
                              }
                              input {
                                &.token-input {
                                }
                              }
                            }
                          }
                        }
                      }
                      #text_preview {
                        .text_preview {
                          padding: 20px 0 0 0;
                          color: #3B4858;
                          .title {
                            font-weight: 600;
                            font-size: 13px;
                            color: $color-gray;
                            span.platformname {
                              text-transform: capitalize;
                            }
                          }
                          .content {
                            color: $color-gray;
                            font-size: 14px;
                          }
                        }
                      }
                      #preview {
                        margin: 20px 0 0 0;
                        #image_preview {
                          display: block;
                          vertical-align: middle;
                          margin: 0;
                          padding: 0;
                          float: none;
                          img {
                            display: inline-block;
                            vertical-align: middle;
                            max-width: 100%;
                          }
                          .spinner {
                            font-size: 8px;
                            margin: 44px auto 24px auto;
                          }
                        }
                        #og_title {
                          font-family: Georgia, serif;
                          font-size: 18px;
                          font-weight: 500;
                          line-height: 22px;
                          word-wrap: break-word;
                          letter-spacing: normal;
                          margin: 0;
                          padding: 0;
                          span {
                            padding: 10px 0;
                            display: block;
                            position: relative;
                          }
                        }
                        #og_description {
                          color: #4b4f56;
                          font-family: Helvetica, Arial, sans-serif;
                          line-height: 16px;
                          overflow: hidden;
                          font-size: 12px;
                          margin: 0;
                          padding: 0;
                          span {
                            padding: 0 0 10px 0;
                            display: block;
                            position: relative;
                          }
                        }
                      }
                    }
                  }
                }
                .box {
                  background-color: #fff;
                  padding: 0;
                  margin: 10px 0 0 0;
                  position: relative;
                  display: block;
                  border-radius: 3px;
                  border: 1px solid $color-light-gray;
                  &.transparent {
                    border: 0;
                    background-color: transparent;
                  }
                }
              }
            }
          }
          > .table#schedule_date.ajax-loading-modal {
            .smc-post-confirmation-wrapper {
              .table {
                &.top {
                  .row {
                    .cell {
                      .icon {
                        i {
                          color: $color-light-gray;
                          border: 0;
                          border-radius: 0;
                          font-size: 32px;
                          width: auto;
                          height: auto;
                          vertical-align: middle;
                          line-height: 32px;
                        }
                      }
                      .table{
                        .row {
                          .icon {
                            padding: 0;
                            width: 32px;
                            margin: 0 auto;
                            display: inline-block;
                            text-align: center;
                            float: none;
                            i {
                              font-size: 20px;
                              color: $color-green;
                            }
                          }
                          &.datepicker {
                            .cell {
                              padding: 5px !important;
                            }
                          }
                          &.timepickers {
                            .cell {
                              padding: 5px !important;
                            }
                            .colon {
                              position: relative;
                              display: inline-block;
                              vertical-align: middle;
                              font-size: 20px;
                              font-weight: 900;
                              padding: 0;
                              line-height: 45px;
                              overflow: visible;
                              text-align: center;
                              color: $color-gray;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        /* END table layout */

        form#post-create-form {
          .cell {
            .box {
              &.add-buttons {
                .button {
                  text-transform: uppercase;
                  font-weight: 400;
                }
              }
              .form-group {
                padding: 20px;
                margin: 0;
                position: relative;
                &.form-group-input {
                  > .input {
                    border: 1px solid #dbe0ea;
                    border-radius: 3px;
                    padding: 0;
                  }
                  .select-items-modal{
                    top: initial;
                    width: 96%;
                    margin: 4px 2% 0 2%;
                  }
                }
                &.form-group-content-editor {
                  padding-top: 0;
                  padding-bottom: 0;
                  > .input {
                    margin: 0;
                    border: 0;
                    border-radius: 0;
                    padding: 0;
                    display: block;
                    > textarea {
                      font-size: 13px;
                      font-weight: 400;
                      color: $color-gray;
                      margin: 0;
                      border: 1px solid #dbe0ea;
                      border-radius: 0 0 3px 3px;
                      padding: 20px !important;
                      &.d-none {
                        + .charcount {
                          display: none;
                        }
                      }
                    }
                    .charcount {
                      display: block;
                      &.overflow {
                        color: #fb0000;
                      }
                    }
                  }
                }
                &.social-tabs {
                  padding-bottom: 0;
                  z-index: 1;
                  position: relative;
                  white-space: nowrap;
                  .select-option {
                    display: inline-block;
                    padding: 0;
                    margin: 0px 5px 0 0;
                    border: 1px solid #dbe0ea;
                    border-bottom: 0;
                    border-radius: 3px 3px 0 0;
                    background-color: #fafafa;
                    float: none;
                    vertical-align: bottom;

                    &.selected {
                      margin-bottom: -1px;
                      padding-bottom: 1px;
                      background-color: #fff;
                    }

                    input[type="checkbox"] {
                      /*
                      display: inline-block;
                      visibility: visible;
                      -webkit-appearance: checkbox;
                      -moz-appearance: checkbox;
                      appearance: checkbox;
                      */
                      &:checked {
                        + label {
                          i.icon {
                            color: #fff;
                            &.icon-twitter {
                              background-color: #5bbfde;
                            }
                            &.icon-facebook {
                                background-color: #4267B2;
                            }
                            &.icon-instagram {
                                background-color: #ffc82b;
                            }
                            &.icon-linkedin {
                                background-color: #007bb6;
                            }
                          }
                        }
                      }
                    }

                    label {
                      padding: 10px;
                      vertical-align: middle;
                      display: inline-block;
                      &:after,
                      &:before {
                        display: none;
                      }
                      i.icon {
                        display: inline-block;
                        color: $color-gray;
                        background-color: #efefef;
                        height: 20px;
                        width: 20px;
                        line-height: 20px;
                        font-size: 20px;
                        vertical-align: top;
                        text-align: center;
                        margin: 0;
                        border-radius: 1px;
                      }
                      p.platform_name {
                        color: $color-gray;
                        display: inline-block;
                        font-size: 12px;
                        font-weight: 400;
                        text-transform: capitalize;
                        vertical-align: middle;
                        padding: 0 10px;
                        margin: 0;
                        white-space: nowrap;
                        line-height: 20px;
                        height: 20px;
                      }
                    }
                  }
                }
              }
              #post-link-input {
                .form-group {
                  &.form-group-input {
                    div.input {
                      position: relative;
                    }
                    div.input:before {
                      /*content: '✕';*/
                      position: absolute;
                      right: 0;
                      top: 0;
                      font-size: 14px;
                      color: $color-text;
                      height: 100%;
                      line-height: 37px;
                      vertical-align: middle;
                      padding: 0 10px;
                      z-index: 1;
                      cursor: pointer;
                    }
                  }
                  #post_link_notice {
                    color: $color-red;
                  }
                }
              }
            }

            .platforms_schedule {
              padding: 0 0 20px 0;
              margin: 0 0 20px 0;
              border-bottom: 1px solid #ccc;
              .platform {
                display: inline-block;
                position: relative;
                float: none;
                margin: 0 30px 0 0;
                padding: 0;
                input[type="checkbox"] {
                    &:checked {
                        ~ .socialmedia_platform_page_id {
                            display: inline-block;
                        }
                    }
                }
                > label {
                  display: inline-block;
                  vertical-align: middle;
                  font-size: 12px;
                  font-weight: 400;
                  white-space: nowrap;
                  text-transform: capitalize;
                  padding-bottom: 11px;
                  padding-top: 11px;
                  &:before {
                  }
                }
                > .socialmedia_platform_page_id {
                  display: none;
                  margin: 0 0 0 10px !important;
                  vertical-align: middle;
                  padding-top: 10px;
                  padding-bottom: 9px;
                }
                &.disabled {
                  p.notice {
                    display: none;
                    position: absolute;
                    bottom: 100%;
                    width: 220px;
                    left: 0;
                    margin: 0 0 0 -17.5px;
                    background-color: rgba(0,0,0,.7);
                    color: #fff;
                    padding: 5px;
                    border-radius: 3px;
                    font-size: 12px;
                    line-height: 1.4;
                    text-align: center;
                    z-index: 3;
                    a {
                      color: $color-blue;
                      text-decoration: underline;
                    }
                    &:before {
                      content: '';
                      position: absolute;
                      height: 0;
                      width: 0;
                      top: 100%;
                      border: 12.5px solid rgba(0,0,0,.7);
                      border-right-color: transparent;
                      border-bottom-color: transparent;
                      border-left-color: transparent;
                      left: 0;
                      margin: 0 0 0 12.5px;
                    }
                  }
                  &:hover {
                    p.notice {
                      display: block;
                    }
                  }
                }
              }
            }

            &.left {
              .box {
                .form-group {
                  &.form-group-input {
                    .input {
                      margin: 0;
                      &.error {
                        border-color: $color-red;
                      }
                      input {
                        border: 0;
                        padding: 10px 34px 10px 10px;
                      }
                    }
                  }
                }
              }
            }

          }
        }
        input[type=submit] {
          display: none;
        }
      }
    }
  }
  &.logged-in {
    #social-media-calendar-create {
      .hero {
        .page-container {
        }
      }
      .content-header {
      }
      .content-main {
        &.create-edit-post {
          /* START table layout */
          form {
            .table {
              .row {
                .cell {
                  &.left {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}