* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  /* stylelint-disable declaration-block-no-shorthand-property-overrides */
  font-size: 100%;
  font: inherit;
  /* stylelint-enable */
  line-height: 1;
  vertical-align: baseline;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
textarea {
  appearance: none;
  outline: none;
  border-radius: 0;

  &:disabled {
    cursor: auto;
  }
}

textarea {
  resize: none;
}

button {
  background: none;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

strong {
  font-weight: bold;
}
