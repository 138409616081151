.clear-results-text {
  padding-bottom: 37px;
  margin-bottom: 37px;
  border-bottom: 1px solid $color-border;
  font-size: 14px;
  color: #687d96;

  a {
    display: inline-block;
    margin-left: 1em;
    font-weight: bold;
    text-decoration: underline;
    color: $color-blue;
  }
}

.no-results-text {
  font-size: 14px;
  color: #687d96;

  a {
    display: inline-block;
    margin-left: 1em;
    font-weight: bold;
    text-decoration: underline;
    color: $color-blue;
  }
}
