//Box-Section ACCOUNT ADMIN

.box-section-edit {
  margin-bottom: 25px;
  border-bottom: 1px solid #dbe0ea;
  padding-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  .section-info {
    width: 50%;
    @include mobile {
      width: 100%;
    }
    &.company {
      border-top: none;
      margin: 0 auto;
      padding: 0;
      @include mobile {
        margin-top: 37px;
        padding-top: 37px;
        border-top: 1px solid #dbe0ea;
      }
    }
  }
  .form-group-account-edit {
    .input {
      display: flex;
      width: 100%;
      font-size: 13px;
      color: $color-black;
    }

    .psuedo-input {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .input,
    .select,
    .mce-tinymce {
      margin-top: 10px;
    }

    i {
      font-size: 16px;
      vertical-align: middle;
    }
  }
  .form-group.half-account-edit {
    display: flex;
    align-items: center;
    @include mobile {
      display:block;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="phone"],
    .select {
      width: 80.27%;
      min-height: 35px;
      @include mobile {
        width:100%;
      }
    }

    label {
      display: block;
      width: 30.6%;
      @include mobile {
        width:100%;
      }
    }
  }

  .upload-file-container {
    display: flex;

    label {
      width: 11.6%;
    }
  }

  .upload-file-wrapper {
    display: flex;
    align-items: center;

    .upload-action {
      margin-left: 15px;
      align-self: flex-start;

      > .button-white {
        margin-top: 10px;
      }

      .button-white {
        width: 100%;
      }

      .button-white:first-child {
        margin-bottom: 5px;
      }
    }
  }

}
