.site-sidebar {
  flex: 0 0 auto;
  width: $site-sidebar-width;
  min-height: 100%;
  background-color: #161e26;
  color: #97a9ba;
  @include mobile {
    max-width:0;
    overflow:hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100vh;
    min-height: 0;
    transition:max-width .3s ease;
  }

  .heading {
    font-size: 14px;
    color: $color-white;
  }
}

.site-sidebar-profile {
  padding: ($gutter * 1.5) $gutter;
  border-bottom: 1px solid #2a323a;
  text-align: center;
  color: $color-white;
  @include mobile {
    padding: 74px 20px 30px;
  }
}

.site-sidebar-profile-image {
  position: relative;
  width: 57px;
  height: 57px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border-radius: $border-radius;
  background: $color-image-background;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 87.5%;
    height: 87.5%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
}

.site-sidebar-nav {
  margin-top: 10px;

  i {
    vertical-align: middle;
  }

  .parent-menu-item {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 30px;
      right: 0;
      display: none;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 9px solid $color-background;
      transform: translateY(-50%);
    }

    > a {
      display: block;
      height: 60px;
      padding: 20px;
      font-size: 14px;
      line-height: 20px;
      color: $color-gray;
      white-space: nowrap;
    }

    i {
      display: inline-block;
      width: 16px;
      margin-right: 10px;
      font-size: 16px;
      color: #323c49;
    }

    &:hover,
    &.active {
      background-color: #222b36;

      > a,
      i {
        color: $color-blue;
      }
    }

    &.active {
      &::after {
        display: block;
      }
    }
  }

  .parent-menu-item.active .submenu {
    display: block;
  }

  .submenu {
    display: none;
    padding-bottom: 24px;
  }

  .submenu-2 {
    display: none;
    padding: 20px 0 0;
  }

  .submenu-item.active .submenu-2 {
    display: block;
  }

  .submenu-item {
    margin-top: 20px;
    margin-left: 20px;

    &:first-child {
      margin-top: 0;
    }

    > a {
      font-size: 14px;
      color: #647687;
    }

    &.active > a,
    > a:hover {
      color: $color-white;
    }
  }
}
