.form-group-input-type-file-with-output {
  padding: 0;
  margin: 0;
  display: inline-block;
  .input {
    margin: 0;
    .controls {
      .control {
        position: relative;
        padding: 0;
        border-radius: 0;
        border: 0;
        text-align: center;
        input {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          display: inline-block;
          opacity: 0;
          cursor: pointer;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          &:hover {
            + .button  {
              color: $color-light-blue;
              border-color: $color-light-blue;
            }
          }
        }
        .button {
          margin: 0;
        }
      }
    }
  }
}

.input-type-file-with-output-upload-details-container {
  display: block;
  position: relative;
  float: none;
  .details {
    box-sizing: content-box;
    border-radius: 3px;
    display: block;
    border: 0;
    background-color: #dee2ec;
    white-space: nowrap;
    margin: 0 20px 20px 20px;
    padding: 10px 20px;
    height: auto;
    width: auto;
    cursor: default;
    position: relative;
    overflow: hidden;
    white-space: normal;
    font-size: 14px;
    font-weight: 400;
    span.file_name {
      color: $color-light-blue;
    }
    span.file_size {
      color: $color-text;
    }
    span.file-delete {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      height: auto;
      display: inline-block;
      table-layout: auto;
      vertical-align: middle;
      i.icon-close {
        float: none;
        display: block;
        padding: 10px;
        &:before {
          color: $color-text;
          font-size: 14px;
        }
        &:hover {
          &:before {
            color: $color-light-blue;
          }
        }
      }
    }
  }
}
