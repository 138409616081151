input[type=checkbox] {
  display: none;
  width: 16px;
  height: 16px;
  visibility: hidden;
}

.form-group input[type=checkbox] {
  margin-top: 0;
}

input[type=checkbox] + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid $color-border;
    border-radius: 0;
    background-color: $color-white;
    transform: translateY(-50%);
  }

  &::after {
    content: "\2714";
    position: absolute;
    top: 50%;
    left: 8px;
    display: none;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: $color-green;
  }
}

input[type=checkbox]:checked + label {
  &::after {
    display: block;
  }
}
