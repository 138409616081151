input[type=radio] {
  flex: 0 0 auto;
  appearance: radio;
  margin-right: 10px;
}

input[type=radio] + label {
  flex: 1 1 auto;
  cursor: pointer;
}
