.channel-create-form {
  width: 100%;
  max-width: 320px;

  .heading {
    font-size: 26px;
  }

  form {
    margin-top: 40px;
  }

  .form-group {
    input[type=radio] + label {
      color: $color-black;
    }
  }

  .form-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 26px;

    a,
    button {
      margin-left: 18px;
    }

    a:first-child,
    button:first-child {
      margin-left: 0;
    }
  }

  .form-controls-cancel {
    font-weight: bold;
    font-size: 12px;
    color: $color-text;
  }
}
