.pagination {
  display: flex;

  li {
    width: 36px;
    height: 36px;
    margin-left: 10px;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    font-size: 12px;
    font-weight: bold;
    background-color: $color-white;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      border-color: $color-blue;
      background-color: $color-blue;
      color: $color-white;
    }

    &.disabled {
      display: none;
    }
  }

  a,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.data-table + .pagination {
  margin-top: 20px;
  justify-content: flex-end;
  float: right;
}
