.site-content {
  flex: 1 1 auto;
  width: 100%;
  min-height: 100%;
  padding: 47px;
  padding-bottom: 94px;

  @media (max-width: $breakpoint-medium) {
    padding-bottom: 141px;
  }
  @include mobile {
    padding: 20px 15px;
  }
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.content-header {
  display: flex;
  padding-bottom: 40px;

  @include mobile {
    flex-direction:column;
    border-bottom: 0;
    padding-bottom:0;
  }
}

.content-header-text {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  align-items: center;
  @include mobile {
    padding-bottom:20px;
    border-bottom:1px solid $color-border;
    margin-bottom: 20px;
  }
  .button {
    margin-left: 26px;
    @include mobile {
      margin-left:auto;
    }
  }

  .heading {
    word-break: break-all;
  }
}

.content-header-control-list {
  flex: 0 1 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
}

.content-header-control-item {
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
  }

  .delete {
    padding: 12px 14px;
    font-size: 14px;
    color: $color-red;

    i {
      vertical-align: middle;
    }
  }
}

.content-header-search {
  flex: 0 0 auto;
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 230px;
  @include mobile {
    max-width:none;
  }
  input {
    width: 100%;
    border-radius: 18px;
    padding-right: 32px;
    padding: 10px;
  }

  button {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 7px;
    font-size: 14px;
    color: $color-black;
    transform: translateY(-50%);
  }
}

.content-main {
  padding-top: 40px;
  border-top: $color-border;

  @include mobile {
    margin-top:20px;
  }

  .full-size {
    margin-left: auto;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #657a94;
    position: relative;

    &:hover {
      color: #396fdb;
    }

    i {
      font-size: 14px;
      top: 2px;
      position: relative;
      padding-right: 5px;
    }
  }

  section {
    margin-top: 37px;
    padding-top: 37px;
    border-top: 1px solid $color-border;

    &:first-of-type {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
  }
}

.content-controls {
  display: flex;
  justify-content: space-between;
  @include mobile {
    display:block;
  }
}

.content-controls-right {
  align-self: flex-end;
  display: flex;
  @include mobile {
    justify-content: space-between;
    margin-top:10px;
  }

  .filter-select {
    flex: 0 0 auto;
    margin-left: 6px;

    &:first-child {
      margin-left: 0;
      @include mobile {
        margin-right:auto;
        .filter-select-options {
          left:0;
          right:auto;
        }
      }
    }
  }

  .filter-select-options {
    left: auto;
    right: 0;

  }
}
