
.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }

.text-left   { text-align: left !important; }
.text-right  { text-align: right !important; }
.text-center { text-align: center !important; }

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

.font-weight-light  { font-weight: 100 !important; }
.font-weight-normal { font-weight: 400 !important; }
.font-weight-bold   { font-weight: 600 !important; }
.font-italic        { font-style: italic !important; }

.text-white { color: #fff !important; }
.text-blue { color: $color-blue !important; }
