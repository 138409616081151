.upload-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(236, 239, 245, 0.9);
  z-index: 9001;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease;

  &.open {
    opacity: 1;
    transform: scale(1);
  }
}

.upload-modal-tabs {
  border-bottom: 1px solid $color-border;
  padding: 0 45px 20px;
  margin-bottom: 20px;

  span {
    position: relative;
    bottom: -5px;
    cursor: pointer;
    padding: 15px;

    &.active {
      background-color: #ffffff;
      border-top: 1px solid $color-border;
      border-left: 1px solid $color-border;
      border-right: 1px solid $color-border;
    }
  }
}

.upload-modal__submit {
  margin-top: 12px;
}

.upload-modal-container {
  width: 60%;
  background-color: white;
  padding: 35px 0 60px;
  border: 1px solid $color-border;
  position: relative;

  > h1 {
    margin-bottom: 35px;
    color: #000000;
    padding: 0 45px;
  }

  .icon-close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  .upload-modal {
    width: calc(100% - 90px);
    margin: 0 auto;
    height: 350px;
    border: 2px dashed $color-border;
    border-radius: $border-radius;
    display: none;

    &.active {
      display: block;
    }

    &.is-error {
      .upload-modal__uploading {
        display: none;
      }

      .upload-modal__error {
        display: block;
      }
    }

    .upload-modal__input {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .upload-modal__file {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        + label {
          max-width: 80%;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          display: inline-block;
          overflow: hidden;
        }
      }

      label {
        display: flex;
        flex-direction: column;

        strong {
          font-size: 20px;
          color: $color-black;
        }

        .upload-modal__dragndrop {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 15px;
          margin-top: 20px;
        }

        .button {
          margin: 20px auto;
        }
      }
    }
  }
}

.upload-modal__dragndrop,
.upload-modal__uploading,
.upload-modal__success,
.upload-modal__error {
  display: none;
}

.upload-modal.is-dragover {
  background-color: $color-gray;
}

.upload-modal.is-uploading .upload-modal__input {
  visibility: none;
}

.upload-modal.is-uploading .upload-modal__uploading {
  display: block;
}

.messaging-wrapper {
  display: flex;
  align-items: center;

  button {
    margin-right: 20px;
  }
}

.upload-modal-wrapper {
  .select-image {
    width: calc(100% - 90px);
    margin: 0 auto;
    height: 350px;
    display: none;

    &.active {
      display: block;
    }

    .select-image__button {
      display: flex;
      margin-left: auto;
      margin-top: 12px;
    }

    .image-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .image {
      flex-basis: calc(33.33% - 10px);
      margin-bottom: 8px;
      cursor: pointer;
      position: relative;

      .check-mark {
        position: absolute;
        left: -4px;
        top: -4px;
      }

      img {
        width: 100%;
        margin: 0 auto;
      }

      &.selection {
        img {
          border: 6px solid $color-blue;
        }
      }
    }
  }
}
