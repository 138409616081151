.spinner {
    margin: auto;
    font-size: 20px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinner_green 1.1s infinite ease;
    animation: spinner_green 1.1s infinite ease;
}

.spinner.white {
    -webkit-animation: spinner_white 1.1s infinite ease;
    animation: spinner_white 1.1s infinite ease;
}

@-webkit-keyframes spinner_green {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em rgb(52, 177, 120), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.5), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.7), 1.8em -1.8em 0 0em rgb(52, 177, 120), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.5), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.7), 2.5em 0em 0 0em rgb(52, 177, 120), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.5), 2.5em 0em 0 0em rgba(52, 177, 120, 0.7), 1.75em 1.75em 0 0em rgb(52, 177, 120), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.5), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.7), 0em 2.5em 0 0em rgb(52, 177, 120), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.5), 0em 2.5em 0 0em rgba(52, 177, 120, 0.7), -1.8em 1.8em 0 0em rgb(52, 177, 120), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.5), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.7), -2.6em 0em 0 0em rgb(52, 177, 120), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.5), -2.6em 0em 0 0em rgba(52, 177, 120, 0.7), -1.8em -1.8em 0 0em rgb(52, 177, 120);
    }
}

@keyframes spinner_green {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em rgb(52, 177, 120), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.5), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.7), 1.8em -1.8em 0 0em rgb(52, 177, 120), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.5), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.7), 2.5em 0em 0 0em rgb(52, 177, 120), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.5), 2.5em 0em 0 0em rgba(52, 177, 120, 0.7), 1.75em 1.75em 0 0em rgb(52, 177, 120), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.5), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.7), 0em 2.5em 0 0em rgb(52, 177, 120), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.2), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.5), 0em 2.5em 0 0em rgba(52, 177, 120, 0.7), -1.8em 1.8em 0 0em rgb(52, 177, 120), -2.6em 0em 0 0em rgba(52, 177, 120, 0.2), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.5), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.7), -2.6em 0em 0 0em rgb(52, 177, 120), -1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(52, 177, 120, 0.2), 1.8em -1.8em 0 0em rgba(52, 177, 120, 0.2), 2.5em 0em 0 0em rgba(52, 177, 120, 0.2), 1.75em 1.75em 0 0em rgba(52, 177, 120, 0.2), 0em 2.5em 0 0em rgba(52, 177, 120, 0.2), -1.8em 1.8em 0 0em rgba(52, 177, 120, 0.5), -2.6em 0em 0 0em rgba(52, 177, 120, 0.7), -1.8em -1.8em 0 0em rgb(52, 177, 120);
    }
}

@-webkit-keyframes spinner_white {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em rgb(255, 255, 255), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em rgb(255, 255, 255), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em rgb(255, 255, 255), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em rgb(255, 255, 255), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em rgb(255, 255, 255), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em rgb(255, 255, 255), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em rgb(255, 255, 255);
    }
}

@keyframes spinner_white {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em rgb(255, 255, 255), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em rgb(255, 255, 255), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em rgb(255, 255, 255), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em rgb(255, 255, 255), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em rgb(255, 255, 255), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em rgb(255, 255, 255), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em rgb(255, 255, 255);
    }
}