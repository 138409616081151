h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  font-weight: bold;
}

h1 {
  font-size: 36px;
  color: $color-black;
  @include mobile {
    font-size:28px;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-size: 15px;
  color: #3a5e80;
}

.page-title {
  padding-bottom: 50px;
  margin-bottom: 64px;
  border-bottom: 1px solid $color-border;

  h1 {
    font-family: $font-family-lato;
  }
}
