.hero-content-item.hero-content-add {
  padding: 0;
  border: 2px dashed #dbe0ea;

  button {
    width: 100%;
    padding: 20px 24px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    color: $color-text;
  }
}
