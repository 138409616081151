.select {
  position: relative;

  select {
    width: 100%;
    appearance: none;
    background-color: transparent;
    border: 1px solid #dbe0ea;
    border-radius: 5px;
    font-size: 14px;
    height: 33px;
    padding-left: 13px;
    outline: 0;
    position: relative;
    z-index: 2;

    &[disabled] {
      color: #7f7f7f;

      & + .caret-down {
        display: none;
      }
    }
  }

  .caret-down {
    position: absolute;
    right: 14px;
    top: 14px;
    font-size: 8px;
    color: #647a92;
  }
}
