.create-edit-article {
  form {
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    & > .left {
      width:100%;
    }
    @include mobile {
      display: block;
    }
  }
  .page-content-add {
    ul {
      display: flex;
      justify-content:center;
      margin-top:12px;
      li {
        width:90px;
        margin:0 10px;
      }
    }
    button {
      width: 100%;
      appearance: none;
      background-color: #fff;
      border: 1px solid #dbe0ea;
      border-radius: 5px;
      font-size: 14px;
      height: 33px;
      outline: 0;
    }
  }
  .page-visibility-select {
    & > label {
      margin-bottom:10px;
      display: block;
    }
  }
}

.blog-block {
  max-width:640px;
  &.page-metadata {
    h2 {
      margin-bottom:10px;
    }
  }
  &.author-date {
    display: flex;
    align-items:center;
    margin-top:18px;
    .author {
      width:100%;
      max-width:360px;
      margin-right:25px;
    }
    .date {
      label {
        display: block;
        margin-bottom:10px;
      }
      input {
        width: 100%;
        appearance: none;
        background-color: #fff;
        border: 1px solid #dbe0ea;
        border-radius: 5px;
        font-size: 14px;
        height: 33px;
        padding-left: 13px;
        outline: 0;
      }

    }
  }
  &.related-posts {
    & > h2 {
      margin-bottom:10px;
    }
    .row {
      display: flex;
      align-items:center;
      justify-content:space-between;
      background-color:#fff;
      height:120px;
      padding:0 25px;
    }
    .form-group-select {
      max-width:260px;
      width:calc(33.33% - 25px);
      margin-top:0;
      label {
        margin-bottom:10px;
        display: block;
      }
      .select {
        margin-top:0;
      }
    }
  } // related posts
  &.comments {
    & > h2 {
      margin-bottom:10px;
    }
    .column {
      background-color:#fff;
    }
    .blog-comment {
      padding:35px;
      border-bottom:1px solid $color-border;
      h4 {
        font-size:14px;
        font-weight: bold;
        margin-bottom:10px;
      }
      p {
        margin-bottom:25px;
      }
      a {
        color:$color-red;
        padding-right: 15px;
        
        i{
          padding-right: 5px;
        }
        .icon-trash {
          margin-right:10px;
        }
      }
    }
  }
}

.blog-new-category {
  margin-top:18px;
  .hide {
    display:none;
  }
  & > div {
    display: flex;
    align-items:center;
    font-size:12px;
    color:$color-gray;
    cursor:pointer;
    span {
      padding-right:5px;
    }
  }
  & > span {
    color:$color-red;
    font-size:12px;
    display: block;
    margin-top:6px;
  }
}

// Category dropdown using autocomplete UI

.ui-autocomplete {
  background-color:#fff;
  width:248px !important;
  padding:15px;
  li {
    margin-bottom:10px;
    cursor:pointer;
  }
}

.tinymce-article-error {
  margin-top: 12px;
  display: block;
  &.hide {
    display: none;
  }
}
