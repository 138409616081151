.ajax-loading-modal {
  display: none;
  position: fixed !important;
  width: 100% !important;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 2000;
  top: 0;
  left: 0;
  &.table {
    > .row {
      position: relative;
      > .cell {
        position: relative;
        text-align: center;
        vertical-align: middle;
        @media (max-width: $breakpoint-large) {
          display: block;
          width: 100% !important;
          padding-left: 2% !important;
          padding-right: 2% !important;
        }
        > .box {
          position: relative;
          padding: 20px;
          background-color: #fff;
          box-shadow: 0 0 20px rgba(0,0,0,0.2);
          border-radius: 5px;
          overflow: hidden;
        }
      }
    }
  }
  &.open {
    display: table;
  }
}