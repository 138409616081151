.button {
  padding: 12px 24px;
  border-radius: $border-radius;
  background-color: $color-blue;
  border: 0;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  color: $color-white;
  white-space: nowrap;
  margin: 0 5px 0 0;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;

  &:hover {
    //color: $color-black;
  }

  &:last-child {
    margin-right: 0;
  }

  &[disabled] {
    background-color: transparent;
    border-color: none;
    color: $color-blue;
    box-shadow: none;
  }
}

.button--invert {
  border: 2px solid $color-blue;
  background-color: $color-white;
  color: $color-blue;
  &:hover {
    color: $color-black;
    border-color: $color-blue;
  }
}

.button--secondary {
  border: 1px solid #cad1de;
  background-color: transparent;
  box-shadow: none;
  color: #697e97;
}

.button--red {
  background-color: $color-red;
  &:hover {
    //color: $color-black;
  }
}

.button--white {
  border: 1px solid $color-light-gray;
  background-color: $color-white;
  color: #8392A6;
  box-shadow: none;
  &:hover {
    color: $color-blue;
    border-color: $color-blue;
  }
}

.button-white {
  padding: 10px 22px;
  border-radius: $border-radius;
  border: 1px solid $color-border;
  background-color: white;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  color: #3a5e80;
  cursor: pointer;
  &:hover {
    color: $color-blue;
    border-color: $color-blue;
  }
}


.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  > .button:first-child {
    margin-right: 5px;
  }
}

/* CUSTOM BUTTONS btn */
.btn {
  border: 0 none;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 27px;
  min-width: 120px;
  text-align: center;
  text-transform: uppercase;

  &.big {
    padding: 6px 12px;
    min-width: 250px;
    display: inline-block;
    margin: 0 5px;
  }

  &.blue {
    background: #009eeb;
  }

  &.red {
    background: #ff4948;
  }
}
