.about-content-list {
  @extend .page-content-list;
}

.about-content-item {
  @extend .page-content-item;
}

.about-content-add {
  width: 100%;
  padding: 2.5% 5%;
  margin-top: 20px;
  border: 2px dashed #dbe0ea;
  border-radius: $border-radius;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: $color-text;
}

.about-content-add-list {
  @extend .page-content-add-list;
}

.about-content-add-item {
  @extend .page-content-add-item;
}
