.subchannels-teasers-item,
.subchannels-teasers-create {
  width: 100%;
  padding: 30px;
  margin-top: 20px;
  border-radius: $border-radius;

  &:first-child {
    margin-top: 0;
  }
}

.subchannels-teasers-item {
  display: flex;
  border: 1px solid $color-border;
  background-color: $color-white;
}

.subchannels-teasers-item-left {
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  .image {
    position: relative;
    display: block;
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border-radius: $border-radius;
    background: $color-image-background;
    overflow: hidden;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 87.5%;
    height: 87.5%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }

  .heading {
    margin-bottom: 2px;
    font-size: 20px;
    line-height: 1.25;
    color: $color-black;
  }

  .account-type {
    font-size: 12px;
    line-height: 1.5;
    color: $color-text;
  }
}

.subchannels-teasers-item-right {
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  @include mobile {
    display: none;
  }

  .last-updated {
    margin-right: 20px;
    font-size: 13px;
  }

  .button {
    box-shadow: none;
  }
}

.subchannels-teasers-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed $color-border;
  text-align: center;
  font-size: 15px;
  color: $color-text;

  i {
    display: block;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 5px 5px 7px 0 rgba($color-black, 0.0625);
    font-size: 16px;
    line-height: 45px;
  }
}
