.seo-og-tag-form {
  max-width: 420px;
  width: 100%;
  margin-top: 160px;
  @include mobile {
    margin-top:0;
  }

  > h4 {
    margin-bottom: 35px;
  }

  .line {
    border-top: 1px solid $color-border;
    width: 100%;
    margin: 35px 0;
  }
}
