.smc_item {
	vertical-align: top;
	padding: 0;
	overflow: visible;
	display: table-cell;
    @media (max-width: $breakpoint-large) {
		display: block;
		width: 100%;
		margin-bottom: 40px;
	}
	> table.box {
		display: table;
		table-layout: auto;
		width: 100%;
		overflow: hidden;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 3px 3px rgba(0,0,0,0.3);
		overflow: hidden;
	    margin: 0;
		> tbody {
			> tr{
				> td {
					p {
						i.icon {
							font-size: 16px;
							&.circle {
								font-size: 6px;
								margin: 0 6px 0 0;
								border-color: $color-gray;
								&:before {
								}
							}
						}
					}
				}
				&.created {
					> td {
						padding: 17px 12px;
						vertical-align: middle;
						text-align: right;
						&.date {
							p {
								font-weight: 100;
								white-space: normal;
								> span.date {
									font-weight: 100;
									opacity: 0.8;
									vertical-align: middle;
									a {
										text-decoration: underline;
										color: $color-blue;
									}
								}
								i.icon {
									height: 14px;
									width: 14px;
									line-height: 14px;
									font-size: 6px;
									box-sizing: content-box;
									padding: 0;
									margin: 0 6px 0 0;
									&.circle {
										margin: 0 12px 0 0;
									}
								}
							}
						}
						&.platforms {
							text-align: left;
							padding: 17px 0 17px 12px;
							white-space: nowrap;

							i.icon {
								color: #fff;
								height: 25px;
								width: 25px;
								display: inline-block;
								vertical-align: top;
								line-height: 25px;
								text-align: center;
								font-size: 24px;
								margin: 0 0 0 6px;
								border-radius: 2px;

								&.platform {
									&:hover {
										box-shadow: inset 0 0 0 2px #000;
									}
								}

								&:first-child {
									margin: 0;
								}

								&.icon-facebook {
									background-color: #4a90e2;
								}
								&.icon-twitter {
									background-color: #5bbfde;
								}
								&.icon-instagram {
									background-color: #ffc82b;
								}
								&.icon-linkedin {
									background-color: #007bb6;
								}
							}
						}
						&.status {
							color: $color-gray;
							font-size: 13px;
							> td {
								padding: 12px 12px 17px 12px;
								vertical-align: middle;
								text-align: left;
								p {
									line-height: 1.4;
									padding: 0 0 0 18px;
									> i.icon {
										margin: -2px 0 0 -18px;
									}
									> span.date {
										color: #000;
										// opacity: 0.6;
										white-space: nowrap;
										// text-decoration: bold;
									}
									a {
										text-decoration: underline;
										color: $color-blue;
									}
								}
							}
						}
						p {
							font-size: 12px;
							line-height: 1;
							font-weight: 100;
							white-space: nowrap;
							vertical-align: middle;
						}
					}
				}
				&.image {
					> td {
						position: relative;
						background-color: $color-gray;
						a {
							display: table-cell;
							width: 100%;
							height: 100%;
							z-index: 1;
							position: relative;
							vertical-align: middle;
							img {
								max-width: 100%;
								max-height: 100%;
								width: 100%;
								vertical-align: middle;
								display: inline-block;
								position: relative;
								float: none;
							}
						}
					}
					&.no-image {
						> td {
							a {
								display: block;
								height: 100%;
								min-height: 120px;
							}
							&:before {
								content: 'No image selected';
								display: block;
								vertical-align: top;
								float: none;
								position: absolute;
								text-align: center;
								width: 100%;
								color: rgba(255,255,255,0.5);
								margin: 0;
								height: 0;
								overflow: visible;
								line-height: 1;
								padding: 0;
								top: 50%;
							}
						}
					}
				}
				&.categories {
					> td {
						position: relative;
						p {
							font-size: 12px;
							padding: 17px 12px 12px 12px;
							line-height: 1.4;
							text-transform: capitalize;
							position: relative;
							span {
								&.name {
									display: none;
									&:nth-child(1),
									&:nth-child(2),
									&:nth-child(3), {
										display: inline-block;
									}
								}
								&.icon.icon-ellipsis {
									color: $color-gray;
									border-color: $color-gray;
									font-size: 10px;
									margin: 0 0 0 5px;
									&:before {
										color: $color-gray;
									}
								}
								&.overflow {
									display: none;
									position: absolute;
									background-color: #fff;
									left: 0;
									z-index: 1;
									width: 100%;
									padding: 0 12px 12px 12px;
									border-radius: 0 0 5px 5px;
									box-shadow: 0 3px 3px rgba(0,0,0,0.3);
								}
							}
							&:hover {
								height: auto !important;
								span {
									&.name {
										display: inline-block;
									}
									&.icon.icon-ellipsis {
										display: none;
									}
									&.overflow {
										display: block;
									}
								}
							}
						}
					}
				}
				&.post_content {
					padding: 0;
					> td {
						padding: 0;
						p {
							font-size: 14px;
							padding: 17px 12px 17px 12px;
							line-height: 1.4;
							word-break: break-word;
						}
					}
				}
				&.statistics {
					> td {
					    vertical-align: middle;
						padding: 8px 0;
						border-top: 1px solid $color-border;
						> table {
							display: table;
							table-layout: fixed;
							width: 100%;
							height: 100%;
							overflow: visible;
							border-spacing: 0;
							border-collapse: separate;
							> tbody {
								> tr {
									> td {
										padding: 8px 0;
										vertical-align: middle;
										text-align: center;
										color: $color-gray;
										p {
											font-size: 12px;
											line-height: 1;
											margin: 0;
											display: inline-block;
											vertical-align: top;
											i {
												font-size: 14px;
												margin: 0;
												display: inline-block;
												vertical-align: top;
												&:before {
												}
											}
											span {
												margin: 0;
												display: inline-block;
												vertical-align: top;
											}
										}
									}
								}
							}
						}
					}
				}
				&.status_footer {
					> td {
						text-align: center;
						vertical-align: middle;
						padding: 0;
						p {
							font-size: 14px;
							padding: 10px 0;
							line-height: 1;
							i.icon {
								&.circle {
									&:before {
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.draft {
		> table.box {
			> tbody {
				> tr {
					&.statistics {
						> td {
							> table {
								> tbody {
									> tr {
										> td {
											p {
											}
											i {
												color: $color-gray;
											}
										}
									}
								}
							}
						}
					}
					&.status_footer {
						> td {
								background-color: $color-light-gray;
							p {
								color: $color-gray;

								i.icon {
									color: $color-gray;
									&.circle {
										border-color: $color-gray;
										&:before {
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.published {
		> table.box {
			> tbody {
				> tr{
					&.created {
						> td {
							&.date {
								i.icon {
									&.circle {
										color: $color-green;
										border-color: $color-green;
									}
								}
							}
						}
					}
					&.statistics {
						> td {
							> table {
								> tbody {
									> tr {
										> td {
											p {
											}
											i {
												&.icon-comments {
													color: #1FB6FF;
												}
												&.icon-heart {
													color: #FF4948;
												}
												&.icon-shares {
													color: #FFC82C;
												}
												&.icon-clicks {
													color: #29EB7F;
												}
											}
										}
									}
								}
							}
						}
					}
					&.status {
						> td {
							p {
								i.icon {
									&.circle {
										color: $color-green;
										border-color: $color-green;
										&:before {
										}
									}
								}
							}
						}
					}
					&.status_footer {
						> td {
							background-color: $color-green;
							p {
								color: $color-white;
								i.icon {
									&.circle {
										color: $color-white;
										border-color: $color-white;
										&:before {
										}
									}
								}
							}
						}
					}

				}
			}
		}
	}
	&.autoscheduled {
		> table.box {
			> tbody {
				> tr{
					&.created {
						> td {
							&.date {
								i.icon {
									color: $color-blue;
									border: 0;
									border-radius: 0;
									height: 16px;
									width: 16px;
									line-height: 16px;
									font-size: 16px;
								}
							}
						}
					}
					&.statistics {
						> td {
							> table {
								> tbody {
									> tr {
										> td {
											p {
											}
											i {
												color: $color-gray;
											}
										}
									}
								}
							}
						}
					}
					&.status {
						> td {
							p {
								i.icon {
									&.circle {
										color: $color-blue;
										border-color: $color-blue;
										&:before {
										}
									}
								}
							}
						}
					}
					&.status_footer {
						> td {
							background-color: $color-blue;
							p {
								color: $color-white;
								i.icon {
									&.circle {
										color: $color-white;
										border-color: $color-white;
										&:before {
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.scheduled {
		> table.box {
			> tbody {
				> tr{
					&.created {
						> td {
							&.date {
								i.icon {
									color: $color-orange;
									border: 0;
									border-radius: 0;
									height: 16px;
									width: 16px;
									line-height: 16px;
									font-size: 16px;
								}
							}
						}
					}
					&.statistics {
						> td {
							> table {
								> tbody {
									> tr {
										> td {
											p {
											}
											i {
												color: $color-gray;
											}
										}
									}
								}
							}
						}
					}
					&.status {
						> td {
							p {
								i.icon {
									&.circle {
										color: $color-orange;
										border-color: $color-orange;
										&:before {
										}
									}
								}
							}
						}
					}
					&.status_footer {
						> td {
							background-color: $color-orange;
							p {
								color: $color-white;
								i.icon {
									&.circle {
										color: $color-white;
										border-color: $color-white;
										&:before {
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
