.hint {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  .hint-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-border;
    border-radius: 10px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    transition: background-color 0.3s ease;

    &::after {
      content: '?';
      color: $color-text;
      font-size: 12px;
      transition: color 0.3s ease;
    }
  }

  .hint-expand {
    position: absolute;
    top: 30px;
    left: -15px;
    transform: scale(0);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 998;

    .hint-expand-inner {
      position: relative;
      padding: 25px;
      color: $color-white;
      background-color: $color-blue;
      border-radius: 5px;
      transition: opacity 0.3s ease;
      min-width: 250px;
      line-height: 1.4;

      &::before {
        content: '';
        position: absolute;
        top: -7px;
        left: 17px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $color-blue;
      }

      &::after {
        content: '';
        position: absolute;
        top: -12px;
        left: 0;
        height: 12px;
        width: 100%;
        background-color: transparent;
      }
    }
  }
}

.hint:hover {
  .hint-inner {
    background-color: $color-blue;

    &::after {
      color: white;
    }
  }

  .hint-expand {
    transform: scale(1);
    opacity: 1;
  }
}
