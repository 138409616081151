.content-subchannels-settings-subscriptions {
  .sortable li {
    width: 29%;
    min-width: 260px;
  }

  .form-controls {
    margin-top: 37px;
  }
}

.subchannels-settings-subscriptions-plans-list {
  width: 52%;
  min-width: 470px;
  @include mobile {
    min-width:0;
    width:100%;
  }
}

.subchannels-settings-subscriptions-plan {
  background-color: #ffffff;
  border: 1px solid #dbdfe8;
  border-radius: 5px;
  padding: 37px;
  margin-top: 14px;

  .plan-name {
    width: 60%;
    float: left;
    @include mobile {
      float:none;
    }
  }

  .plan-active {
    float: right;
    margin-top: 32px;
    @include mobile {
      float:none;
      margin-top: 15px;
    }

    label {
      margin-left: 5px;
      color: #657a94;
      display: inline-block;
      vertical-align: middle;
      vertical-align: -webkit-baseline-middle;
    }

    input[type=checkbox] + label {
      display: inline-block;
      padding-left: 65px;
    }

    input[type="checkbox"]:checked ~ label {
      color: #0072dc;
    }
  }

  .toggle-wrapper {
    display: inline-block;
  }

  .plan-free, .plan-default {
    clear: both;
    overflow: auto;
    padding-top: 14px;

    label {
      float: left;
      color: #adb6c3;
      font-weight: normal;
      line-height: 16px;
    }

    .input {
      margin-top: 0;
      float: left;
      display: inline-block;
      width: auto;
    }

    input[type=checkbox] + label {
      height: 16px;
    }
  }

  .plan-default {
    padding-top: 0;
  }
}

.content-subchannels-settings-subscriptions-billing {
  border-top: 1px solid #dbe0ea;
  margin-top: 35px;
  padding-top: 35px;

  h4 {
    margin-bottom: 11px;
  }
}

.content-subchannels-settings-subscriptions-stripe {
  padding: 34px;
  border: 1px solid #dbdfe8;
  border-radius: 5px;
  background-color: #f2f5fa;
  margin-bottom: 15px;

  .input {
    margin-bottom: 10px;
  }

  select {
    width: 100%;
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #dbe0ea;
    border-radius: 5px;
    font-size: 14px;
    height: 33px;
    padding-left: 13px;
    outline: 0;
  }
}

.content-subchannels-settings-subscriptions-stripe-add {
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  color: #657a94;
  font-weight: 600;
  text-align: center;
  display: block;
}

.content-subchannels-settings-subscriptions-plans-order {
  .inactive {
    .sortable-handle {
      color: #b9bfc9;
      background-color: #f1f3f8;
      border-color: #e8ebf2;
    }
  }
}

.plan-permissions {
  .data-table {
    margin-top: 20px;
    table-layout: fixed;

    thead {
      background-color: #ffffff;
    }

    thead tr {
      background-color: #ffffff;
    }

    tbody tr {
      background-color: #ffffff;
      white-space: nowrap;
    }

    th {
      font-size: 16px;
      color: #222e44;
      text-align: center;
    }

    td {
      font-size: 13px;
      line-height: 1;
      font-weight: bold;
      color: #222e44;
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &.spacer {
        color: #657a94;
        font-weight: normal;
      }

      &.permission-name.child-permission {
        text-indent: 20px;
        @include mobile {
          white-space:initial;
          text-indent:initial;
        }
      }

      &.has-value {
        input[type="text"] {
          width: 50%;
          float: left;
          text-align: left;
          padding: 6px 8px;
        }

        .perms-infinite {
          float: right;
          line-height: 30px;
          position: relative;

          .label-infinite {
            font-size: 26px;
            line-height: 26px;
            display: inline-block;
            vertical-align: sub;
            font-weight: 100;
            font-family: aria;
            position: absolute;
            left: -25px;
            top: 2px;
          }
        }
      }
    }

    th,
    td {
      padding: 16px 20px;
      border: 1px solid #dbe0ea;

      &.has-value {
        padding: 6px 20px;
        @include mobile {
          padding: 16px 20px;
        }
      }

      &:nth-child(even) {
        background-color: #f9fafc;
      }

      input[type="text"] {
        text-align: center;

        &:disabled {
          background-color: transparent;
          border-color: transparent;
          color: #c8cbd0;
        }
      }

      input[type="checkbox"] {
        &:disabled {
          ~ label {
            color: #c8cbd0;
          }

          + label {
            &::before {
              background-color: transparent;
            }

            &::after {
              color: #657a94;
            }
          }
        }
      }
    }

    input[type=checkbox] + label {
      padding-left: 30px;
      padding-top: 8px;
      padding-bottom: 8px;

      &::before {
        width: 30px;
        height: 30px;
      }

      &::after {
        left: 16px;
        font-size: 16px;
        color: #0072dc;
      }
    }
  }
}

//Stripe Plan - name

.stripe_name_input {
  font-size: 13px;
  font-weight: 700;
  display: block;
  width: 100%;
  padding: 9px 16px;
  background-color: #ffffff;
  color: #222e44;
  border: 1px solid #dbe0ea;
  border-radius: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-memberedge {
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  display: none;

  .modal-box {
    background: #fff;
    position: relative;
    left: 50%;
    top: 50%;
    max-width: 632px;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);

    .modal-box-head {
      padding: 60px 30px;

      h2 {
        font-size: 24px;
        color: #56606f;
        font-weight: 300;
        margin-bottom: 15px;
        text-align: center;
      }

      p {
        font-size: 14px;
        color: #8392a6;
        line-height: 16px;
        text-align: center;
      }
    }

    .modal-box-footer {
      background: #ebeef5;
      padding: 40px 15px;
      text-align: center;
    }

    .close-modal {
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 20px;
      color: #000;
    }
  }
}
