.color-picker {
  label {
    position: relative;
    display: block;
    width: 48px;
    height: 48px;
    margin-top: 0;
    border-radius: $border-radius;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      bottom: 8px;
      right: 8px;
      display: block;
      width: 0;
      height: 0;
      border-right: 8px solid $color-white;
      border-top: 8px solid transparent;
      box-shadow: 2px 2px 2px 0 rgba($color-black, 0.25);
    }
  }
}

