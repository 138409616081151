.filter-select {
  position: relative;
  z-index: 1;
  color: #3a5e80;

  &.open {
    .filter-select-input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .filter-select-input::after {
      transform: translateY(-37.5%) rotate(45deg) scale(-1);
    }

    .filter-select-options {
      display: block;
    }
  }
}

.filter-select-input {
  position: relative;
  width: 100%;
  padding: 9px 16px;
  padding-right: 30px;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  background-color: $color-background;
  font-size: 12px;
  font-weight: bold;
  color: inherit;
  @include mobile {
    font-size:11px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 14px;
    display: block;
    width: 6px;
    height: 6px;
    border: 2px solid #ccd2de;
    border-top: none;
    border-left: none;
    transform: translateY(-62.5%) rotate(45deg);
  }
}

.filter-select-options {
  position: absolute;
  bottom: 1px;
  left: 0;
  display: none;
  min-width: 100%;
  max-height: 164px;
  padding: 16px;
  border: 1px solid $color-border;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  transform: translateY(100%);
  background-color: #f9fafc;
  box-shadow: 0 8px 22px rgba(#21262d, 0.0625);
  overflow: auto;
  white-space: nowrap;
  font-size: 12px;
  color: #7f93a6;

  a:hover,
  button:hover {
    color: #3a5e80;
  }
}

.filter-select-option {
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}
