input[type=text],
input[type=phone],
.tokenfield {
  font-size: 13px;
  font-weight: 400;
  display: block;
  width: 100%;
  padding: 0;
  color: $color-text;
  border: 0;

  @include placeholder {
    color: $color-input-placeholder;
  }

  &[disabled] {
    color: #7f7f7f;
  }
}

input[type=text],
input[type=phone] {
  border: 1px solid $color-light-gray;
  border-radius: 3px;
  padding: 8px 8px 9px 8px;
}
