.site-main {
  flex: 1 1 auto;
  display: flex;
  height: calc(100% - #{$site-header-height});
  margin-top: $site-header-height;
  @include mobile {
    position:relative;
  }
  .menu-overlay {
    display: none;
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color:rgba(255,255,255,0);
    transition:background-color .3s ease;
  }
  &.menu-open {
    .site-sidebar {
      @include mobile {
        max-width:250px;
      }
    }
    .site-content {
      @include mobile {
        .menu-overlay {
          display:block;
          background-color:rgba(255,255,255,0.8);
        }
      }
    }
  }
}
