.block {
  margin-top: 18px;

  &:first-child {
    margin-top: 0;
  }
}

.block-4,
.block-5,
.block-18,
.block-19,
.block-22,
.block-30 {
  max-width: 640px;
}


