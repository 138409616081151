.content-subchannels-settings-style {
  section {
    @extend .form-column;
  }

  section header {
    @extend .form-column-left-large;

    p {
      font-size: 13px;
      margin-top: 0.5em;
    }
  }

  .form-group {
    @extend .form-column-right-large;
  }
}

.content-subchannels-settings-style-logo {
  .form-group {
    display: flex;
  }

  .image {
    flex: 0 0 auto;
    position: relative;
    width: 128px;
    height: 128px;
    margin-right: 10px;
    border-radius: 3px;
    background-color: #ffffff;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: auto;
      height: 101%;
      min-width: 101%;
      min-height: 101%;
      transform: translate(-50%, -50%);
    }
  }

  .controls {
    flex: 0 1 auto;
    width: 128px;

    .control {
      margin-top: 10px;
    }

    .control:first-child {
      margin-top: 0;
    }
  }

  label,
  button {
    display: block;
    width: 100%;
    padding: 9px 16px;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    background-color: $color-white;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: $color-black;
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.content-subchannels-settings-style-background-color,
.content-subchannels-settings-style-font-family {
  .input {
    display: flex;
    align-items: center;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  input {
    margin-right: 20px;
  }

  label {
    margin-top: 0 !important;
  }

  span {
    display: block;
  }

  .option-heading {
    font-size: 36px;
  }

  .option-text {
    font-size: 13px;
    line-height: 1.5;
  }

  .option-amiri-regular {
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    font-weight: normal;
  }

  .option-roboto-light {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
  }

  .option-roboto-regular {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  .option-lato-bold {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: bold;
  }

  .option-lato-regular {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
  }
}


