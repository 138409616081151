.image-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(236, 239, 245, 0.9);
  z-index: 9001;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease;

  &.open {
    opacity: 1;
    transform: scale(1);
  }

  .image-select-container {
    width: 60%;
    background-color: white;
    padding: 35px 50px 60px 45px;
    border: 1px solid $color-border;
    position: relative;

    > h1 {
      margin-bottom: 35px;
      color: #000000;
    }

    .icon-close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
  }
}
