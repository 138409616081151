.content-subchannel {
  .data-table {
    margin-top: 20px;
  }
}

.quick-links {
  display: flex;
  margin-top: 20px;
  @include mobile {
    flex-wrap:wrap;
  }
}

.quick-links-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: ($gutter * 2);
  text-align: center;

  &:first-child {
    margin-left: 0;
  }

  @include mobile {
    margin-left:0;
    margin-bottom:20px;
    width:50%;
  }
}

.quick-links-item-icon {
  display: inline-block;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: $color-blue;
  box-shadow: 5px 5px 7px 0 rgba($color-black, 0.0625);
  text-align: center;
  color: $color-white;

  i {
    font-size: 20px;
    line-height: 66px;
  }
}

.quick-links-item-cta {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  color: $color-blue;
}

.quick-links-item-delete {
  .quick-links-item-icon {
    background-color: $color-red;
  }

  .quick-links-item-cta {
    color: $color-red;
  }
}
