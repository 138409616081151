.d-none {
	display: none !important;
}
.d-block {
	display: block !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-table {
	display: table !important;
}