.login-form {
  width: 100%;
  max-width: 320px;
}

.login-form-area {
  width: 100%;

  form {
    margin-top: 40px;
  }
}

.login-form-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;

  .form-group {
    margin-top: 0;
  }
}

.login-form-links {
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid $color-border;
  color: $color-text;

  a {
    font-size: 13px;
  }
}
