.form-group {
  .input {
    display: flex;
    width: 100%;
    font-size: 13px;
    color: $color-black;
  }

  .psuedo-input {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .input,
  .select,
  .mce-tinymce {
    margin-top: 10px;
  }

  i {
    font-size: 16px;
    vertical-align: middle;
  }
}

// .form-row {
//   border-bottom: 1px solid $color-border;
//   padding-bottom: 60px;
//   margin-bottom: 70px;
// }

.form-group.half {
  display: flex;
  align-items: center;
  @include mobile {
    display:block;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="phone"],
  .select {
    width: 27.27%;
    min-height: 35px;
    @include mobile {
      width:100%;
    }
  }

  label {
    display: block;
    width: 11.6%;
    @include mobile {
      width:100%;
    }
  }
}

.upload-file-container {
  display: flex;

  label {
    width: 11.6%;
  }
}

.upload-file-wrapper {
  display: flex;
  align-items: center;

  .upload-action {
    margin-left: 15px;
    align-self: flex-start;

    > .button-white {
      margin-top: 10px;
    }

    .button-white {
      width: 100%;
    }

    .button-white:first-child {
      margin-bottom: 5px;
    }
  }
}
