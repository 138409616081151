.bulk-actions {
  @extend .filter-select;
  .content-controls-left & {
    @include mobile {
      z-index: 2;
    }
  }
}

.bulk-actions-input {
  @extend .filter-select-input;

  background-color: $color-white !important;
}

.bulk-actions-options {
  @extend .filter-select-options;

  background-color: $color-white !important;
  color: $color-text !important;

  button {
    color: inherit;
  }
}

.bulk-actions-option {
  @extend .filter-select-option;

  input[type=radio] {
    display: none;
  }
}
