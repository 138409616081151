.social-media-calendar {
	position: relative;

	.hero {
		.page-container {
			margin: 0 !important;
		}
	}

	.content-header-search {
		flex: 0 0 auto;
		position: relative;
		right: 0;
		width: 100%;
		max-width: 230px;
		margin: 20px 0;
		float: right;

		@include mobile {
			max-width:none;
		}
		input {
			width: 100%;
			border-radius: 18px;
			padding-right: 32px;
			padding: 10px;
		}

		button {
			position: absolute;
			z-index: 1;
			top: 50%;
			right: 14px;
			font-size: 14px;
			color: $color-black;
			transform: translateY(-50%);
		}
	}

	.content-main {
		.clear-results-text {
			border: 0;
			padding: 0 20px 20px 20px;
		}

		ul.pagination {
			text-align: right;
			display: block;
			padding: 20px;
			li {
				display: inline-block;
				float: none;
				&:hover {
					border-color: $color-blue;
				}
				> a {
					font-size: 16px;
					font-weight: 100;
				}
				> span {
					font-size: 16px;
					font-weight: 100;
				}
			}
		}
		#smc-posts-grid {
			margin-right: -40px;
			@media (max-width: $breakpoint-large) {
				margin-right: 0;
			}
			> table {
				display: table;
				table-layout: fixed;
				width: 100%;
				overflow: visible;
				border-collapse: separate;
				border-spacing: 20px 20px;
				margin-left: -20px;
				@media (max-width: $breakpoint-large) {
					margin-left: 0;
					display: block;
					width: 100%;
				}
				&.filters_table {
					table-layout: auto;
					border-spacing: 20px 0;
					background-color: transparent;
					margin-left: -20px;
					@media (max-width: $breakpoint-large) {
						border-spacing: 0;
						margin-left: 0;
					}
					>tbody {
						@media (max-width: $breakpoint-large) {
							display: block;
						}
						>tr {
							@media (max-width: $breakpoint-large) {
								display: block;
							}
							>td {
								vertical-align: middle;
								white-space: nowrap;
								@media (max-width: $breakpoint-large) {
									display: block;
								}
							}
						}
					}
					div.select-modal-button {
						.select-items-modal {
							&.select_categories {
							}
						}
					}
				}
				> tbody {
					@include mobile {
						display: block;
						width: 100%;
					}
					> tr {
						@include mobile {
							display: block;
							width: 100%;
						}
						> td.smc_item {
						}
					}
				}
			}
		}
	}
	&#social-media-calendar-fullcalendar {
		.content-main {
			#smc-posts-grid {
				margin-right: 0;
				> table {
					&.filters_table {
						border-spacing: 0;
						margin-left: 0;
						.select-modal-button {
							margin: 0;
							>.label {
								white-space: nowrap;
							}
						}
					}
					&.calendar {
						border-spacing: 0;
						border-top: 20px solid transparent;
						margin-left: 0;
					}
				}
			}
		}
	}
}
