.alert-danger {
  li {
    padding-bottom: 20px;
    color: $color-red;
  }
}

.container-alert-error {
  background-color: #f2f2f2;
  border: 1px solid #e7ebef;
  margin-bottom: 40px;
  padding: 20px 30px;
  width: 100%;

  ul {
    li {
      margin: 0 0 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
