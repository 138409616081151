.site-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: $site-header-height;
  border-bottom: 1px solid $color-border;
  background-color: $color-white;
  box-shadow: 0 4px 8px rgba($color-black, 0.03);

  > .inner {
    display: flex;
    height: 100%;
  }

  .heading {
    flex: 0 0 auto;
    width: $site-sidebar-width;
    height: 100%;
    border-right: 1px solid $color-border;
    background-color: $color-background;
    text-align: center;
    line-height: $site-header-height;
    @include mobile {
      display: none;
    }
    .hamburger {
      display:none;
      @include mobile {
        display:block;
      }
    }
  }
}

.site-header-nav {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  @include mobile {
    justify-content: space-between;
  }
  .has-sidebar & {
    @include mobile {
      justify-content: flex-end;
    }
  }
}

.site-header-nav-links {
  display: flex;

  a,
  button {
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: $site-header-height;
  }
}

.site-header-nav-admin {
  position: relative;
  display: flex;
  margin-left: 12px;
  border-left: 1px solid $color-border;
  span {
    @include mobile {
      display: none;
    }
  }

  i {
    margin-left: 4px;
    vertical-align: middle;
  }

  &:hover {
    .site-header-account {
      background-color: #222b36;
      color: $color-white;
    }

    .site-header-nav-admin-dropdown {
      display: block;
    }
  }
}

.site-header-account {
  display: inline-block;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: $site-header-height;
  color: $color-text;
}

.site-header-nav-admin-dropdown {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  display: none;
  width: 160px;
  padding: 24px;
  transform: translateY(100%);
  background-color: #222b36;
  color: $color-white;

  a,
  button {
    font-weight: bold;
    font-size: 12px;
    color: #97a9ba;

    &:hover {
      color: $color-white;
    }
  }

  li {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}
