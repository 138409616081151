#resource-edit-form,
#media-edit-form {
  display: flex;
  @include mobile {
    display:block;
  }
}

.content-main-image {
  flex: 0 1 auto;
  width: 100%;

  img {
    width: 100%;
  }

  .generic-file-wrapper {
    width: 100%;
    height: 307px;
    background-color: #eceff5;
    color: #657a94;
    position: relative;

    i {
      font-size: 45px;
      width: 48px;
      height: 60px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .content-main-preview-image {
    margin-top: 35px;
    padding-top: 25px;
    border-top: 1px solid #dbe0ea;

    h2 {
      margin-bottom: 17px;
    }

    > div {
      float: left;
    }

    img {
      height: 100px;
      width: auto;
      position: relative;
      display: block;

      &.none {
        display: none;
      }
    }
  }
}

.content-main-image-cta,
.content-main-preivew-image-cta {
  display: flex;
  align-items: center;
  padding-top: 15px;

  .button-white:first-child {
    margin-right: 12px;
  }

  .upload {
    position: relative;
    z-index: 0;
    cursor: pointer;

    input {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.content-main-preivew-image-cta {
  display: block;
  width: 112.5px;
  float: left;
  padding-top: 0;
  margin-left: 20px;

  a,
  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
}

.content-main-metadata {
  flex: 0 0 auto;
  width: 320px;
  margin-left: 20px;
  @include mobile {
    width:100%;
    margin-left:0;
    margin-top:20px;
  }
}

.content-main-metadata-section {
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  margin-top: 20px;
  padding: 24px 36px;

  &:first-child {
    margin-top: 0;
  }
}

.content-main-metadata-section-controls {
  h2 {
    color: $color-blue;
  }

  i {
    vertical-align: middle;
  }

  .controls {
    display: flex;
    margin-top: 10px;
  }

  .button {
    padding: 10px 16px;
    margin-left: 6px;
    box-shadow: none;

    &:first-child {
      margin-left: 0;
    }
  }
}

.content-main-metadata-section-metadata {
  font-size: 13px;

  .metadata {
    margin-top: 16px;
    word-break: break-all;

    &:first-child {
      margin-top: 0;
    }
  }

  span {
    display: inline-block;
    margin-right: 1em;
    color: #657a94;
  }
}
