.toggle-wrapper {
  position: relative;
  overflow: hidden;
  height: 30px;

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    height: 1px;
    width: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  input[type="checkbox"] + label {
    position: relative;
    z-index: 0;
    display: block;
    height: 100%;
    padding-left: (60px + 14px);
    line-height: 30px;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      display: block;
      width: 60px;
      height: 30px;
      border-radius: 100px;
      background: $color-light-gray;
      transition: background $duration-fast;
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 2px;
      display: block;
      height: 26px;
      width: 26px;
      background: $color-white;
      border-radius: 50%;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      transform: translateY(-50%);
      transition: left $duration-fast;
    }
  }

  input[type="checkbox"]:checked + label {
    color: $color-blue;

    &::before {
      background: $color-blue;
    }

    &::after {
      left: (60px - 26px - 2px);
    }
  }
}
