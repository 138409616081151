.sortable {
  position: relative;
  z-index: 0;
  margin-top: 10px;
}

.sortable .sortable {
  z-index: 1;
  left: 40px;
  min-height: 10px;
  margin-top: 0;

  .sortable {
    display: none;
  }

  .sortabe-add {
    display:none;
  }

  &.has-children {
    margin-top: 10px;
    .hide {
      display: none;
    }
  }

  + .sortable-add {
    z-index: 1;
    left: 40px;
    display:none;
    &.show {
      display:block;
    }
  }

  &.has-children + .sortable-add {
    display: block;
  }
}

.sortable.has-children .sortable.has-children {
  .sortable-add {
    display:none;
  }
}

.sortable li,
.ui-sortable-placeholder,
.sortable-add {
  margin-bottom: 10px;
}

.sortable-handle,
.ui-sortable-placeholder,
.sortable-add {
  position: relative;
  width: 280px;
  height: 48px;
  padding: 16px 20px;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  background-color: $color-white;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  color: $color-black;
  white-space: nowrap;
}

.sortable li.open {
  > .sortable-handle {
    display: none;
  }

  > .sortable-edit {
    display: block;
  }
}

.sortable-handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  i {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 12px;
    color: $color-border;
  }
}

.ui-sortable-placeholder,
.sortable-add {
  border: 2px dashed #dbe0ea;
  background-color: transparent;
  color: $color-text;
}

.ui-sortable-helper {
  .sortable-edit-button,
  .sortable-add {
    display: none;
  }
}

.sortable-edit {
  display: none;
  width: 280px;
  padding: 16px 20px;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  background-color: $color-white;
  color: $color-black;
}

.sortable-edit .form-group-input-type-checkbox label input[type=checkbox] {
  display: inline;
  visibility: visible;
  appearance:checkbox;
  vertical-align: middle;
}

.sortable-edit .form-group-input-type-checkbox label span {
  padding-left:4px;
  vertical-align: middle;
}

.sortable-edit-button {
  position: absolute;
  top: 50%;
  right: 0;
  padding-left: 10px;
  transform: translate(100%, -50%);
  color: #b6becb;
}

.sortable-edit-controls {
  margin-top: 18px;
  .toggle-wrapper {
    margin: 0 0 18px 0;
  }
}

.sortable-edit-controls-save {
  display: inline-block;
  box-shadow: none;
}

.sortable-edit-controls-cancel {
  display: inline-block;
  margin-left: 6px;
  box-shadow: none;
  background-color: $color-background;
  color: $color-text;
}

.sortable-edit-controls-remove {
  display: block;
  margin-top: 18px;
  color: $color-red;
  font-size: 12px;
  font-weight: bold;
}
