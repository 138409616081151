// Fix to add cursor pointer to input FILE types
::-webkit-file-upload-button {
  cursor:pointer;
}

.form-group.form-group-input-type-file {
  .input {
    display: flex;
  }

  .image {
    flex: 0 0 auto;
    width: 78px;
    height: 78px;
    background-color: $color-border;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .controls {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    min-width: 150px;
  }

  .control {
    @extend .button-white;

    position: relative;
    z-index: 0;
    padding: 10px 20px;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
