@function calculate-rem($size) {
  @return ($size / 16px) * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculate-rem($size);
}

@mixin kerning($kerning) {
  letter-spacing: ($kerning / 1000) + em;
}

@mixin mobile {
  @media (max-width: $breakpoint-medium) {
    @content;
  }
}
