.wysiwyg {
  display: none;
  visibility: hidden;
}

form {
  .mce-tinymce {
    width: 100%;
    border: 1px solid $color-border;
    border-radius: $border-radius;
  }

  .mce-toolbar-grp,
  .mce-btn {
    background-color: $color-background;
  }

  .mce-ico {
    color: $color-text;
  }

  .mce-edit-area {
    border-top: 1px solid $color-border;
  }
}

.mce-content-body {
  padding: 9px 16px;
}
