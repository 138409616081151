.content-channels-settings-general,
.content-subchannels-settings-general {
  .form-group {
    @extend .form-column;
    @include mobile {
      display:block;
    }
  }

  .form-group,
  .form-group:first-child {
    margin-top: 18px;
  }

  .form-group label {
    @extend .form-column-left-small;
  }

  .form-group .input {
    @extend .form-column-right-large;
    @include mobile {
      max-width:none;
      width:100%;
      margin-bottom:5px;
    }
    input[type=radio], select {
      margin-top: 11px;
    }
  }

  .form-group-input-type-checkbox {
    width: 25%;
  }
}
.form-group-blog-settings {
  .toggle-wrapper input[type=checkbox]+label {
    display: inline;
  }
  div:nth-child(2) {
    margin-right:37px;
  }
}
