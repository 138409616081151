@font-face {
  font-family: "admin-icons";
  src: url('../fonts/icons/admin-icons.eot');
  src:
    url('../fonts/icons/admin-icons.eot?#iefix') format('eot'),
    url('../fonts/icons/admin-icons.woff2') format('woff2'),
    url('../fonts/icons/admin-icons.woff') format('woff'),
    url('../fonts/icons/admin-icons.ttf') format('truetype'),
    url('../fonts/icons/admin-icons.svg#admin-icons') format('svg');
}

@mixin icon-styles {
  font-family: "admin-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}


.icon {
  vertical-align: middle;
  &.circle {
    width: 16px;
    height: 16px;
    font-size: 6px;
    border: 1px;
    border-style: solid;
    border-radius: 50%;
    border-color: #fff;
    line-height: 1;
    vertical-align: middle;
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
    &:before {
      line-height: 14px;
    }
  }
}

@function icon-char($filename) {
  $char: "";

  @if $filename == alert {
    $char: "\E001";
  }

  @if $filename == arrow-down {
    $char: "\E002";
  }

  @if $filename == arrow-up {
    $char: "\E003";
  }

  @if $filename == calendar {
    $char: "\E004";
  }

  @if $filename == calendar_2 {
    $char: "\E005";
  }

  @if $filename == caret-down {
    $char: "\E006";
  }

  @if $filename == caret-left {
    $char: "\E007";
  }

  @if $filename == caret-right {
    $char: "\E008";
  }

  @if $filename == caret-up {
    $char: "\E009";
  }

  @if $filename == check {
    $char: "\E00A";
  }

  @if $filename == clicks {
    $char: "\E00B";
  }

  @if $filename == clock {
    $char: "\E00C";
  }

  @if $filename == clock_2 {
    $char: "\E00D";
  }

  @if $filename == close {
    $char: "\E00E";
  }

  @if $filename == comments {
    $char: "\E00F";
  }

  @if $filename == display {
    $char: "\E010";
  }

  @if $filename == duplicate {
    $char: "\E011";
  }

  @if $filename == ellipsis {
    $char: "\E012";
  }

  @if $filename == facebook {
    $char: "\E013";
  }

  @if $filename == fullscreen {
    $char: "\E014";
  }

  @if $filename == generic-file {
    $char: "\E015";
  }

  @if $filename == google-plus {
    $char: "\E016";
  }

  @if $filename == heart {
    $char: "\E017";
  }

  @if $filename == home {
    $char: "\E018";
  }

  @if $filename == instagram {
    $char: "\E019";
  }

  @if $filename == key {
    $char: "\E01A";
  }

  @if $filename == library {
    $char: "\E01B";
  }

  @if $filename == linkedin {
    $char: "\E01C";
  }

  @if $filename == mail {
    $char: "\E01D";
  }

  @if $filename == media {
    $char: "\E01E";
  }

  @if $filename == menu {
    $char: "\E01F";
  }

  @if $filename == minus {
    $char: "\E020";
  }

  @if $filename == newsletter {
    $char: "\E021";
  }

  @if $filename == phone {
    $char: "\E022";
  }

  @if $filename == pin {
    $char: "\E023";
  }

  @if $filename == pinterest {
    $char: "\E024";
  }

  @if $filename == play {
    $char: "\E025";
  }

  @if $filename == plus {
    $char: "\E026";
  }

  @if $filename == preview {
    $char: "\E027";
  }

  @if $filename == scheduled {
    $char: "\E028";
  }

  @if $filename == scheduled_2 {
    $char: "\E029";
  }

  @if $filename == search {
    $char: "\E02A";
  }

  @if $filename == settings {
    $char: "\E02B";
  }

  @if $filename == shares {
    $char: "\E02C";
  }

  @if $filename == signal {
    $char: "\E02D";
  }

  @if $filename == sortable {
    $char: "\E02E";
  }

  @if $filename == subchannels {
    $char: "\E02F";
  }

  @if $filename == template {
    $char: "\E030";
  }

  @if $filename == thrash {
    $char: "\E031";
  }

  @if $filename == trash {
    $char: "\E032";
  }

  @if $filename == triangle-up {
    $char: "\E033";
  }

  @if $filename == twitter {
    $char: "\E034";
  }

  @if $filename == update {
    $char: "\E035";
  }

  @if $filename == user {
    $char: "\E036";
  }

  @if $filename == users {
    $char: "\E037";
  }

  @if $filename == warning {
    $char: "\E038";
  }

  @if $filename == youtube {
    $char: "\E039";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &::#{$insert} {
    /* stylelint-disable at-rule-empty-line-before */
    @if $extend {
      @extend %icon;
    }
    @else {
      @include icon-styles;
    }
    /* stylelint-enable */

    content: icon-char($filename);
  }
}

.icon-alert {
  @include icon(alert);
}

.icon-arrow-down {
  @include icon(arrow-down);
}

.icon-arrow-up {
  @include icon(arrow-up);
}

.icon-calendar {
  @include icon(calendar);
}

.icon-calendar_2 {
  @include icon(calendar_2);
}

.icon-caret-down {
  @include icon(caret-down);
}

.icon-caret-left {
  @include icon(caret-left);
}

.icon-caret-right {
  @include icon(caret-right);
}

.icon-caret-up {
  @include icon(caret-up);
}

.icon-check {
  @include icon(check);
}

.icon-clicks {
  @include icon(clicks);
}

.icon-clock {
  @include icon(clock);
}

.icon-clock_2 {
  @include icon(clock_2);
}

.icon-close {
  @include icon(close);
}

.icon-comments {
  @include icon(comments);
}

.icon-display {
  @include icon(display);
}

.icon-duplicate {
  @include icon(duplicate);
}

.icon-ellipsis {
  @include icon(ellipsis);
}

.icon-facebook {
  @include icon(facebook);
}

.icon-fullscreen {
  @include icon(fullscreen);
}

.icon-generic-file {
  @include icon(generic-file);
}

.icon-google-plus {
  @include icon(google-plus);
}

.icon-heart {
  @include icon(heart);
}

.icon-home {
  @include icon(home);
}

.icon-instagram {
  @include icon(instagram);
}

.icon-key {
  @include icon(key);
}

.icon-library {
  @include icon(library);
}

.icon-linkedin {
  @include icon(linkedin);
}

.icon-mail {
  @include icon(mail);
}

.icon-media {
  @include icon(media);
}

.icon-menu {
  @include icon(menu);
}

.icon-minus {
  @include icon(minus);
}

.icon-newsletter {
  @include icon(newsletter);
}

.icon-phone {
  @include icon(phone);
}

.icon-pin {
  @include icon(pin);
}

.icon-pinterest {
  @include icon(pinterest);
}

.icon-play {
  @include icon(play);
}

.icon-plus {
  @include icon(plus);
}

.icon-preview {
  @include icon(preview);
}

.icon-scheduled {
  @include icon(scheduled);
}

.icon-scheduled_2 {
  @include icon(scheduled_2);
}

.icon-search {
  @include icon(search);
}

.icon-settings {
  @include icon(settings);
}

.icon-shares {
  @include icon(shares);
}

.icon-signal {
  @include icon(signal);
}

.icon-sortable {
  @include icon(sortable);
}

.icon-subchannels {
  @include icon(subchannels);
}

.icon-template {
  @include icon(template);
}

.icon-thrash {
  @include icon(thrash);
}

.icon-trash {
  @include icon(trash);
}

.icon-triangle-up {
  @include icon(triangle-up);
}

.icon-twitter {
  @include icon(twitter);
}

.icon-update {
  @include icon(update);
}

.icon-user {
  @include icon(user);
}

.icon-users {
  @include icon(users);
}

.icon-warning {
  @include icon(warning);
}

.icon-youtube {
  @include icon(youtube);
}

