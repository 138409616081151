form {
  .form-group {
    margin-top: 18px;


    &:first-child {
      margin-top: 0;
      @include mobile {
        margin-top:10px;
      }
    }
  }

  .help-block {
    margin-top: 10px;
    font-size: 13px;
    color: $color-red;
  }

  // .form-row-heading {
  //   font-size: 15px;
  //   font-weight: 700;
  //   margin-bottom: 15px;
  // }

  .cta-align {
    width: 40%;
    display: inline-block;
  }
}
