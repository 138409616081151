.channels-teasers {
  display: flex;
  flex-wrap: wrap;
  @include mobile {
    justify-content:space-between;
  }
}

.channels-teasers-item,
.channels-teasers-create {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(#{(100% / 3)} - #{(20px * 2 / 3)});
  margin-top: 20px;
  margin-left: 20px;
  padding: 60px 40px;
  border-radius: $border-radius;
  text-align: center;
  @include mobile {
    width:calc(50% - 5px);
    margin:0 0 10px;;
  }
  &:nth-child(3n+1) {
    margin-left: 0;
  }

  &:nth-child(-n+3) {
    margin-top: 0;
  }
}

.channels-teasers-item {
  background-color: $color-white;
  border: 1px solid $color-border;

  .heading {
    font-size: 20px;
    color: $color-black;
  }

  .button {
    margin-top: 20px;
    box-shadow: none;
  }
}

.channels-teasers-create {
  border: 2px dashed $color-border;
  font-size: 15px;
  color: $color-text;

  i {
    display: block;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 5px 5px 7px 0 rgba($color-black, 0.0625);
    font-size: 16px;
    line-height: 45px;
  }
}
