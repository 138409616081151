.page-has-update {
  margin-bottom: 37px;
  padding-bottom: 37px;
  border-bottom: 1px solid #dbe0ea;

  > .inner {
    width: 320px;
    padding: 24px 36px;
    margin-top: 20px;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    background-color: $color-white;
  }
}

.page-has-update__copy {
  color: $color-blue;
}

.page-has-update__controls {
  display: flex;
  margin-top: 10px;

  form {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  .button {
    padding: 10px 22px;
    font-size: 12px;
  }
}
