$color-facebook: #3B5998;
$color-twitter: #1DA1F2;
$color-linkedin: #0077B5;

$Lato-Bold: 600 medium Lato;
$Lato-Medium: 300 medium Lato;
$Lato-Semibold: 300 medium Lato;
$Lato-Regular: 200 medium Lato;
$Lato-Light: 100 medium Lato;

$text-color-1: #3B4858;

$border-admin-1: 1px solid #E0E6ED;
$border-admin-2: 1px solid #DEE2EC;

.new-design-select {
  background: #fff;
  border: $border-admin-2;
  border-radius: 3px;
  color: #8392a6;
  font: $Lato-Regular;
  font-size: 12px;
  height: 32px;
  width: 200px;
  text-align: center;
  padding-left: 10px;
}

.new-btn-design {
  background: #fff;
  border: 1px solid #dee2ec;
  border-radius: 3px;
  color: #8392a6;
  font: 200 medium Lato;
  font-size: 12px;
  line-height: 1;
  height: 32px;
  text-align: left;
  padding: 0 8.5px;
}

.progressSelector{
  margin-bottom: 30px;
}

.progress-bar {
  .text-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3px;
    .left {
      .title {
        font: $Lato-Medium;
        font-size: 14px;
        color: #8392A6;
        margin-left: 5px;
      }
    }
    .right {
      .posts {
        font: $Lato-Regular;
        font-size: 11px;
        color: #303B47;
        margin-right: 10px;
      }
      .percent {
        font: $Lato-Bold;
        font-size: 14px;
        color: #0072DC;
        letter-spacing: 0;
        margin-right: 8px;
      }
    }
  }
}

.new-line {
  border: $border-admin-2;
}

.new-content-header-options {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.new-bulk-actions-input {
  background: #FFFFFF;
  border: $border-admin-2;
  border-radius: 3px;
  color: #8392A6;
  //font: $Roboto-Regular;
  font: $Lato-Regular;
  font-size: 12px;
  line-height: 1;
  height: 32px;
  width: 120px;
  text-align: left;
}

.new-input-daterange {
  input[type=text] {
    background: #FFFFFF;
    border: $border-admin-2;
    border-radius: 3px;
    color: #8392A6;
    //font: $Roboto-Regular;
    font: $Lato-Regular;
    font-size: 12px;
    height: 32px;
    width: 200px;
    text-align: center;
  }
}

.color-blue {
  color: #009EEB!important;
}
.color-green {
  color: #1BBEB0!important;
}
.color-orange {
  color: #FFC82C!important;
}
.no-border {
  border: none!important;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.mt-60 {
  margin-top: 60px!important;
}
.mt-30 {
  margin-top: 30px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mb-40 {
  margin-bottom: 40px!important;
}
.mb-30 {
  margin-bottom: 30px!important;
}
.mb-25 {
  margin-bottom: 25px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.mb-15 {
  margin-bottom: 15px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.mr-10 {
  margin-right: 10px!important;
}
.pt-50 {
  padding-top: 50px!important;
}
.pt-40 {
  padding-top: 40px!important;
}
.pb-30 {
  padding-bottom: 30px!important;
}
.pb-25 {
  padding-bottom: 25px!important;
}
.text-center {
  text-align: center;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.new-design-report {
  .box-btn-admin {
    background: #FFFFFF;
    border: $border-admin-2;
    border-radius: 3px;
    font: $Lato-Regular;
    font-size: 12px;
    color: #8392A6;
    letter-spacing: 0;
    width: 120px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .table {
    background: #fff;
    table {
      border-collapse: collapse;
      width: 100%;
    }
    /*table, th, td {*/
    table, th {
      border: $border-admin-1;
    }
    td {
      border-right: $border-admin-1;
    }
    th {
      color: #0072DC;
      font: $Lato-Bold;
      font-size: 16px;
      height: 70px;
      text-align: center;
      vertical-align: middle;
    }
    td {
      color: #222E44;
      font: $Lato-Regular;
      font-size: 14px;
      height: 50px;
      text-align: center;
      vertical-align: middle;
    }
    th:first-child, td:first-child {
      padding-left: 20px;
      text-align: left;
    }
    td:first-child {
      color: #222E44;
      font: $Lato-Bold;
      font-size: 14px;
    }
    tr:nth-child(even) {
      background-color: #f9fafc;
    }
  }
  .row-wrapper-boxes {
    display: flex;
    flex-direction: row;
    .box {
      margin: 0;
      .row-video-item {
        align-items: center;
        border-bottom: $border-admin-1;
        display: flex;
        flex-direction: row;
        padding: 15px 0;
        .img {
          img {
            border-radius: 50%;
            height: 40px;
            margin-right: 10px;
            width: 40px;
          }
        }
        .group-number {
          border-right: $border-admin-1;
          padding: 5px 10px;
          .title {
            font: $Lato-Semibold;
            font-size: 18px;
            color: #3B4858;
          }
          .subtitle {
            font: $Lato-Regular;
            font-size: 11px;
            color: #8392A6;
            img {
              height: 12px;
              margin-right: 3px;
              width: 12px;
            }
          }
        }
        .group-number:last-child {
          border-right: none;
        }
      }
      .row-video-item:last-child {
        border-bottom: none;
      }
    }
  }
  @media (max-width: 768px) {
    .row-wrapper-boxes {
      flex-direction: column;
      .box {
        margin: 20px 0 0!important;
      }
    }
  }
  .box-post {
    height: 500px;
    overflow-y: scroll;
    padding: 0 46px 0 20px;
    .post {
      display: flex;
      flex-direction: row;
      border: $border-admin-1;
      border-radius: 3px;
      margin-bottom: 20px;
      img {
        width: 190px;
        height: 100px;
      }
      .content-post {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px 30px 20px 20px;
        .text {
          font: $Lato-Regular;
          font-size: 12px;
          color: #3B4858;
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          img {
            margin: 0 5px 0 15px;
            width: 12px;
            height: 12px;
          }
          float: right;
          font: $Lato-Light;
          font-size: 12px;
          color: #8392A6;
        }
      }
    }
  }
  .box:first-child {
    margin-top: 0.5px;
  }
  .box.item-vertical {
    padding: 40px 0;
    border-bottom: $border-admin-1;
  }
  .box.item-vertical:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
  .box {
    background: #FFFFFF;
    border-radius: 3px;
    margin: 20px 0;
    padding: 17px 30px 50px;
    .text-1 {
      //font-family: Lato-Bold;
      font: $Lato-Bold;
      font-size: 18px;
      color: $text-color-1;
    }
    .text-2 {
      font: $Lato-Semibold;
      font-size: 12px;
      color: $text-color-1;
    }
    .text-3 {
      font: $Lato-Bold;
      font-size: 36px;
      color: $text-color-1;
    }
    .text-4 {
      font: $Lato-Bold;
      font-size: 40px;
      color: $text-color-1;
    }
    .text-5 {
      font: $Lato-Regular;
      font-size: 16px;
      color: #3B4858;
    }
    .text-6 {
      font: $Lato-Semibold;
      font-size: 12px;
      color: #c4c4c4;
      line-height: 15px;
    }
    .icon-1 {
      img {
        height: 50px;
        width: 50px;
      }
    }
    .row-center {
      display: flex;
      justify-content: center;
      .content {
        width: 70%;
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      .item {
        border-right: $border-admin-1;
        flex: 1;
      }
      .item:last-child {
        border-right: none;
      }
    }
    @media (max-width: 768px) {
      .column-rates {
        .content {
          width: 100%;
        }
      }
      .content {
        flex-direction: column;
        .item {
          border-right: none;
          border-bottom: $border-admin-1;
          padding: 20px 0;
        }
        .item:last-child {
          border-right: none;
          border-bottom: none;
          padding: 20px 0;
        }
      }
    }
    .socialmedia {
      font: $Lato-Medium;
      font-size: 24px;
    }
    .facebook {
      color: $color-facebook;
    }
    .twitter {
      color: $color-twitter;
    }
    .linkedin {
      color: $color-linkedin;
    }
    .email-marketing {

    }
    .embedded {

    }
    .community {

    }
    .new-row-community {
      align-items: center;
      display: flex;
      flex-direction: row;
      .new-left {
        margin-right: 50px;
        width: 40%;
        .item:last-child {
          border-right: $border-admin-1;
        }
      }
      .new-right {
        width: 60%;
      }
    }
    @media (max-width: 768px) {
      .new-row-community {
        flex-direction: column;
        .new-left, .new-right {
          margin: 0;
          width: 100%;
        }
        .content.new-left {
          .item:last-child {
            border-bottom: $border-admin-1;
          }
        }
      }
    }
  }
}


.fade {
                opacity: 0;
                -webkit-transition: opacity .15s linear;
                -o-transition: opacity .15s linear;
                transition: opacity .15s linear;
            }
            .fade.in {
                opacity: 1;
            }
            .modal-backdrop {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1040;
                background-color: #000;
            }
            .modal-backdrop.fade {
                filter: alpha(opacity=0);
                opacity: 0;
            }
            .modal-backdrop.in {
                filter: alpha(opacity=50);
                opacity: .5;
            }
            .modal-open {
                overflow: hidden;
            }
            .modal {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1050;
                display: none;
                overflow: hidden;
                -webkit-overflow-scrolling: touch;
                outline: 0;
            }
            .modal-dialog {
                position: relative;
                width: auto;
                margin: 10px;
            }
            .modal-content {
                position: relative;
                background-color: #fff;
                -webkit-background-clip: padding-box;
                background-clip: padding-box;
                border: 1px solid #999;
                border: 1px solid rgba(0,0,0,.2);
                border-radius: 6px;
                outline: 0;
                -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
                box-shadow: 0 3px 9px rgba(0,0,0,.5);
            }
            .modal-header {
                padding: 15px;
                border-bottom: 1px solid #e5e5e5;
            }
            .modal-title {
                margin: 0;
                line-height: 1.42857143;
            }
            .modal-body {
                position: relative;
                padding: 15px;
            }
            .modal-footer {
                padding: 15px;
                text-align: right;
                border-top: 1px solid #e5e5e5;
            }
            .btn-default{
                background-color: #0099eb;
            }
            .close {
                float: right;
                font-size: 21px;
                font-weight: 700;
                line-height: 1;
                color: #000;
                text-shadow: 0 1px 0 #fff;
                filter: alpha(opacity=20);
                opacity: .2;
            }
            button.close {
                -webkit-appearance: none;
                padding: 0;
                cursor: pointer;
                background: 0 0;
                border: 0;
            }
            .close:focus, .close:hover {
                color: #000;
                text-decoration: none;
                cursor: pointer;
                filter: alpha(opacity=50);
                opacity: .5;
            }
            .modal-header .close {
                margin-top: -2px;
            }
            @media (min-width: 768px) {
                .modal-dialog {
                    width: 600px;
                    margin: 30px auto;
                }
                .modal-content {
                    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
                    box-shadow: 0 5px 15px rgba(0,0,0,.5);
                }
            }

.export-data{
  background-color: #fff;
  margin-left: 5px;
  padding: 9px;
  font-size: 12px;
  font-weight: 700;
}
.page-range-tool{
  margin-top: 20px;
  float: left;

  .page-range{
    position: relative;
    float: left;
    top: 0px;
  }

  .page-range-dropdown{
    display: inline-block;
    top: 0px;
    padding-left: 15px;
  }
}

.resources-show-nav__back img {
    height: 11px;
    margin-right: 10px;
    width: 6px;
}