.page-content-list {
  margin-top: 18px;
}

.page-content-item {
  padding: 20px 24px;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  background-color: #e9ecf2;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .top-bar {
    display: flex;
  }

  .top-bar-header {
    flex: 1 1 auto;
    display: flex;
    font-size: 15px;

    button {
      margin-left: 16px;
      font-weight: bold;
      font-size: 13px;
      color: #b6becb;
    }
  }

  .top-bar-controls {
    flex: 0 0 auto;

    button {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: 20px;
      }
    }

    i {
      font-size: 12px;
      color: $color-text;
    }

    .icon-trash {
      font-size: 14px;
      color: $color-red;
    }

    .icon-caret-down {
      font-size: 10px;
      color: #56606F;
    }
  }

  .drawer {
    display: none;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $color-border;
  }

  &.open > .drawer {
    display: block;
  }

  .field > ul {
    margin-top: 24px;
  }

  .field > ul li {
    padding-top: 24px;
    margin-top: 12px;
    border-top: 1px solid $color-border;

    &:first-child {
      margin-top: 0;
    }
  }

  .add-fields {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 4px;
    margin-top: 24px;
    border-top: 1px solid $color-border;
    text-align: left;
    font-weight: bold;
    font-size: 15px;
    color: $color-text;
  }
  &.none-feature-enterprise {
    display: none;
  }
}

.field-value-item {
  position: relative;
}

.field-controls {
  position: absolute;
  top: 18px;
  right: 0;

  i {
    font-size: 12px;
    color: $color-text;
  }

  .icon-trash {
    font-size: 14px;
    color: $color-red;
  }
}

.page-content-add {
  padding: 5% 7.5%;
  margin-top: 20px;
  border: 2px dashed #dbe0ea;
  border-radius: $border-radius;

  .heading {
    text-align: center;
    color: $color-text;
  }
}

.page-content-add-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5%;
}

.page-content-add-item {
  display: flex;
  width: (94% / 4);
  margin-top: 2%;
  margin-left: 2%;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  background: $color-white;

  &:nth-child(-n+4) {
    margin-top: 0;
  }

  &:nth-child(4n+1) {
    margin-left: 0;
  }

  button {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    padding: 7.5% 15% 12.5%;
  }

  .image {
    flex: 0 0 auto;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .text {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 1em;
    text-align: center;
    font-size: 13px;
    color: $color-text;
  }
}

input[type="date"] {
  font-size: 13px;
  font-weight: 700;
  display: block;
  width: 180px;
  padding: 9px 16px;
  color: #222e44;
  border: 1px solid #dbe0ea;
  border-radius: 3px;
}

.page-content-item {
  .top-bar .top-bar-header .form-group {
    width: 100%;

    .input {
      display: inline-block;
      margin-top: 0;
      width: 60%;

      input[type="text"] {
        background-color: #ffffff;
      }

      .page-permalink span {
        margin-left: 3px;
      }
    }

    .toggle-wrapper {
      float: right;

      input[type="checkbox"] + label {
        width: 0;
      }
    }
  }
}

.page-content-sub-item {
  padding: 20px 24px;
  border: 1px solid #dbe0ea;
  border-radius: 3px;
  background-color: #e9ecf2;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .top-bar-sub-controls {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;

    button {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-left: 20px;
      }
    }

    i {
      font-size: 12px;
      color: #657a94;

      &.icon-trash {
        font-size: 14px;
        color: #ca3939;
      }

      &.icon-caret-down {
        font-size: 10px;
        color: #56606F;
      }
    }
  }

  .drawer {
    display: none;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $color-border;
  }

  &.open > .drawer {
    display: block;
  }
}

.add-fields.custom-add-fields {
  color: #657a94;
  border: 2px dashed #dbe0ea;
  margin-top: 12px;
  padding: 3.5% 7.5%;
  text-align: center;
}

.field-16-section-cta-text, .field-16-section-cta-link {
  display: inline-block;
  margin-top: 18px;
}
.field-16-section-cta-text {
  padding-right: 20px;
  width: 25%;
}
.field-16-section-cta-link {
  width: 55%;
}

.field-17-section-email-phone, .field-17-section-address {
  display: inline-block;
  margin-top: 18px;
  width: 49%;
}

.field-17-section-email-phone {
  padding-right: 20px;
}

.field-17-section-address {
  .form-group {
    margin-top: 4px;
    .input {
      margin-top: 4px;
    }

    &:first-child {
      margin-top: 0;
      .input {
        margin-top: 10px;
      }
    }
  }
}

.plan-type-title {
  margin-top: 25px;
  margin-bottom: 10px;
}
.textarea-question {
  margin-bottom: 10px;
}
.container-cards-picture-enterprise {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include mobile{
    display: block;
  }
  .card-picture-enterprise {
    width: 50%;
    padding: 10px;
    @include mobile{
      width: 100%;
      padding: 0px;
    }
  }
  .upload-image {
    margin-left: 25%;
  }
}
