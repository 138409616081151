.table {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  position: relative;
  &.layout-auto {
    table-layout: auto;
  }
  &.layout-fixed {
    table-layout: fixed !important;
  }
  &.width-auto {
    width: auto !important;
  }
  &.no_responsive {
    .row {
      display: table-row !important;
      > .cell {
        display: table-cell !important;
        width: auto !important;
      }
    }
  }

  .row {
    display: table-row;
    > .cell {
			display: table-cell;
			vertical-align: middle;
			position: relative;
			float: none;

      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }

      &.nowrap {
        white-space: nowrap;
      }

			@media (max-width: $breakpoint-large) {
				display: block !important;
				width: 100% !important;
				padding: 0 !important;
			}
      &.spacer {
        @media (max-width: $breakpoint-large) {
          display: none !important;
        }
      }
      &.no_responsive {
        display: table-cell !important;
        width: auto !important;
      }
      &.va-top {
        vertical-align: top !important;
      }
      &.va-middle {
        vertical-align: middle !important;
      }
      &.va-bottom {
        vertical-align: bottom !important;
      }
         &.col {
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
        &.col-80 {
          width: 80%;
        }
        &.col-75 {
          width: 75%;
        }
        &.col-70 {
          width: 70%;
        }
        &.col-66 {
          width: 66.6666%;
        }
        &.col-60 {
          width: 60%;
        }
        &.col-50 {
          width: 50%;
        }
        &.col-40 {
          width: 40%;
        }
        &.col-35 {
          width: 35%;
        }
        &.col-33 {
          width: 33.3333%;
        }
        &.col-25 {
          width: 25%;
        }
        &.col-15 {
          width: 10%;
        }
        &.col-10 {
          width: 10%;
        }
        &.col-5 {
          width: 5%;
        }
      }
      > .box {
        position: relative;
        display: block;
      }
    }
  }
}
