.content-passwords {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  h1 {
    font-size: 25px;
  }

  header p {
    margin-top: 1em;
    font-size: 13px;
  }

  .login-form-controls {
    justify-content: flex-end;
  }
}
