.content-page-edit {
  .form-group-list {
    margin-top: 18px;
  }
}

.content-page-title {
  max-width: 640px;

  .title-and-status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-group {
      flex: 1 1 auto;
    }

    .toggle-wrapper {
      flex: 0 0 auto;
      margin-left: 37px;
    }
  }

  .page-visibility-select {
    margin-top: 37px;

    > .input {
      margin-top: 10px;
    }
  }
}

.page-permalink {
  margin-top: 12px;
  font-size: 13px;
  color: $color-text;

  a {
    display: inline-block;
    margin-left: 0.5em;
    color: $color-blue;
  }

  strong {
    font-weight: bold;
  }
}

.page-template-section-hero-carousel {
  .toggle-wrapper {
    float: right;
    position: relative;
    top: -8px;
  }
}
