.flash-message {
  position: relative;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 37px;
    padding-bottom: 37px;
    border-bottom: 1px solid #dbe0ea;
  }

  .flash-message-icon {
    position: absolute;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #657a94;
    border-radius: 10px;
    width: 18px;
    height: 18px;
    transition: background-color 0.3s ease;

    &::after {
      content: '!';
      color: #ffffff;
      font-size: 12px;
      transition: color 0.3s ease;
    }
  }

  .flash-alert {
    color: #657a94;
    margin-left: 37px;
  }
}
