.confirmation-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(236, 239, 245, 0.9);
  z-index: 9001;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease;

  &.open {
    opacity: 1;
    transform: scale(1);
  }
}

.confirmation-modal {
  width: 30%;
  height: 200px;
  background-color: #ffffff;
  padding: 50px 5% 37.5px;
  text-align: center;
  position: relative;

  .icon-close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  p {
    color: #262d47;
    padding-bottom: 20px;
    font-size: 18px;
  }

  .button {
    color: #262d47;
    background-color: #ebeff8;
    margin: 0 5px;

    &:hover {
      color: #ffffff;
      background-color: #0072dc;
    }
  }
}
