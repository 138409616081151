.none {
  display: none;
}
log {
  padding: 20px;
    text-align: left;
    font-size: 12px;
    overflow: auto;
    white-space: pre-wrap;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    background-color: #fff;
}
