.form-column {
  display: flex;
  align-items: flex-start;

  .input:first-child {
    margin-top: 0;
  }
}

.form-column.form-group,
.form-column .form-group {
  label {
    margin-top: 11px;
  }

  input {
    margin-top: 0;
    padding: 10px;
  }
}

.form-column-left,
.form-column-left-small,
.form-column-left-medium,
.form-column-left-large,
.form-column-left-extra-large {
  flex: 0 0 auto;
  margin-right: 37px;
  width: 50%;
  max-width: 132px;
}

.form-column-right,
.form-column-right-small,
.form-column-right-medium,
.form-column-right-large,
.form-column-right-extra-large {
  flex: 1 1 auto;
  width: 50%;

  &.form-group,
  &.input {
    margin-top: 0;
  }
}

.form-column-left-small,
.form-column-right-small {
  max-width: 86px;
}

.form-column-left-medium,
.form-column-right-medium {
  max-width: 132px;
}

.form-column-left-large,
.form-column-right-large {
  max-width: 320px;
}

.form-column-left-extra-large,
.form-column-right-extra-large {
  max-width: 640px;
}
