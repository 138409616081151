.data-table {
  width: 100%;
  border: 1px solid $color-border;
  background-color: $color-white;
  @include mobile {
    border-top:0;
  }
  .checkbox-column {
    width: 56px;
  }

  input[type=checkbox] + label {
    padding-left: 16px;
  }

  thead {
    background-color: #f9fafc;
    border-bottom: 1px solid #dbe0ea;
    @include mobile {
      th{
        display: none;
      }
      th:nth-child(1), th:nth-child(2){
        display: table-cell;
      }
    }
  }

  .page-row {
    display: flex;
    align-items: center;
    @include mobile {
      position:relative;
      display: block;
    }

    i {
      position:absolute;
      right: 25px;
      top: 21px;
      font-size: 7px;
    }

    .mobile-subcontent {
      display: none;
      margin-top:20px;
      & > p, & > span {
        margin-bottom:10px;
      }
      &.open {
        @include mobile {
          display: block;
        }
      }
    }
  }

  .has-update {
    margin-left: 40px;
    color: $color-blue;
  }

  tbody td {
    font-size: 14px;
    vertical-align: middle;
    @include mobile {
      vertical-align:top;
      display:none;
      &:nth-child(1),&:nth-child(2) {
        display:table-cell;
      }
    }
  }

  tr:nth-child(even) {
    background-color: #f9fafc;
  }

  th,
  td {
    padding: 18px 20px;
  }

  th,
  .order-by {
    font-weight: bold;
    font-size: 13px;
    color: $color-text;
    text-align: left;
  }

  .order-by {
    position: relative;
    padding-right: 14px;

    &.active::after,
    &:hover::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 0;
      height: 0;
      border-top: 4px solid #cdd3dc;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      transform: translateY(-50%);
    }

    &.desc::after,
    &.active:hover::after {
      transform: translateY(-50%) scale(-1);
    }
  }

  tbody {
    .active {
      color: $color-blue;
    }

    .inactive {
      color: #cfcfcf;
    }
  }
}

.content-controls + .data-table {
  margin-top: 10px;
}

.data-table-file {
  display: flex;
  align-items: center;
}

.data-table-file-image {
  flex: 0 0 auto;
  position: relative;
  display: block;
  width: 74px;
  height: 74px;
  margin-right: 20px;
  background-color: $color-white;
  overflow: hidden;

  a {
    width: 100%;
    height: 100%;
    display: block;
    background-color: #eceff5;
    color: #657a94;
  }

  .generic-file {
    position: absolute;
    font-size: 25px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 25px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}

.data-table-file-meta {
  word-break: break-all;
}

.data-table-meta-name {
  margin-bottom: 4px;
  font-weight: bold;
  color: $color-blue;
}
